@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

* {
    font-family: "Noto Sans KR", sans-serif;
    padding: 0px;
    margin: 0px;
}

.mypage {
}

.myPageLeftSide {
    width: 150px;
    border-radius: 5px;
    background-color: white;
    border: thin solid #e7e7e7;
    box-shadow: 10px 10px 20px #e7e7e7;
}

.myPageLeftSide ul > li {
    margin: auto;
    width: 130px;
    height: 110px;
    padding: 20px;
    border-bottom: thin solid #e7e7e7;
    text-align: center;
    color: #1e88e5 !important;
    transition: all 0.3s ease;
}

.myPageLeftSide ul > li:last-child {
    border-bottom: none;
}

.myPageLeftSide ul > li:hover {
    color: #1e88e5;
    transition: all 0.3s ease;
}

.myPageLeftSide ul > li > a {
    color: #9b9b9b;
}

.myPageLeftSide ul > li > a:hover {
    color: #1e88e5;
}

.mypageLeftsideActive a {
    color: #1e88e5 !important;
}

.mypageHeader {
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: thin solid #e7e7e7;
    box-shadow: 10px 10px 20px #e7e7e7;
}

.menuBox {
    height: 80px;
    padding-top: 10px;
    margin: 10px 0;
    width: 90%;
    border-right: thin solid #e7e7e7;
    text-align: center;
    color: #1e88e5;
}

.menuBox img {
    width: 40px;
    transition: all 0.3s ease;
}

.menuBox:hover img {
    width: 55px;
    transform: translateY(-0.5rem);
    transition: all 0.3s ease;
}

.menuBoxLast {
    padding-top: 10px;
    height: 80px;
    margin: 10px 0;
    text-align: center;
    color: #1e88e5;
}

.menuBoxLast img {
    width: 40px;
    transition: all 0.3s ease;
}

.menuBoxLast:hover img {
    width: 55px;
    transform: translateY(-0.5rem);
    transition: all 0.3s ease;
}

.mypageContents .MuiTableRow-root {
    font-size: 1.2rem !important;
    padding: 15px 10px !important;
}

.mypageContents .MuiTableCell-root {
    font-size: 1.2rem !important;
    padding: 15px 10px !important;
}

.mypageContents .MuiTypography-body2 {
    font-size: 1.2rem !important;
}

.mypageTable .MuiTableCell-root {
    font-size: 1.2rem !important;
    line-height: 1 !important;
}

.MuiMenuItem-root {
    font-family: "Noto Sans KR";
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 22.4px !important;
    letter-spacing: -0.15px !important;
}
