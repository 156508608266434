@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');


.statistic {

  background-color: "#f0f5f9";
  display: flex;
  border-radius: 5px;
}

.wrapper,
.list_header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 30%;
  padding: 40px 2.5%;
}

.wrapper {
  position: relative;
  transition: all 0.5s ease;
  border-radius: 10px;
  margin: 5% 3%;
  border: thin solid #dbdbdb !important;
  box-shadow: 10px 10px 20px #dbdbdb !important;
  background-color: #f6eedf;
  transform: translateY(0%);


  &:hover {
    background: rgb(252, 249, 231);
    transition: all 0.2s ease;
    transform: translateY(-5%);


    & .button {
      //opacity: 1;
    }

    & .extra {
      opacity: 1;
    }
  }
}

.wrapper:first-child {
  background-color: #e6efff;
}

.wrapper:last-child {
  background-color: #f8f9fa;
}

.list_header {
  overflow: hidden;
  position: relative;

  &:hover .list_front {
    transform: translateY(-100%);
  }

  &:hover .list_back {
    transform: translateY(0);
  }

  & .list_front,
  & .list_back {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    transition: all 1s ease;
    text-align: center;
    padding: 0 50px;
  }

  & .list_front {
    & i {
      font-family: 'Noto Sans KR', sans-serif;
      font-size: 2.5rem;
      margin-top: 5rem;
    }

    & h3 {
      font-family: 'Noto Sans KR', sans-serif;
      text-align: center;
      transition: all 0.5s ease;
      width: 100%;
    }

    & b {
      font-family: 'Noto Sans KR', sans-serif;
      font-weight: 600;
    }
  }

  & .list_back {
    transform: translateY(100%);

    & p {
      line-height: 2.3;
      width: 100%;
    }
  }
}

.extra {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: -100%;
  opacity: 0;
  pointer-events: none;
  padding: 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card {
  & h4 {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 2.5rem;
    text-align: center !important;
    line-height: 1.5;
    margin-bottom: 1rem;
    font-weight: 300;
  }

  & p {
    font-family: 'Noto Sans KR', sans-serif;
    text-align: center !important;
    margin-bottom: 2rem;
    line-height: 1.4;
  }

  & .button {
    font-size: 1.4rem !important;
    //opacity: 0.5;
    //transition: all 0.5s ease;
  }
}

.icon {
  width: 100px;
  height: 100px;
  margin: auto;
  margin-bottom: 2rem;

  & img {
    width: 100%;
  }
}

.negative_space {
  display: block;
  width: 100%;
  background: rgb(240, 240, 240);
}

@media (max-width: 1200px) {
  .wrapper,
  .list_header {
    padding-bottom: 20px;
    padding-top: 30px;
  }

  .list_header .list_front i {
    margin-top: 3rem;
  }

  .card {
    .icon {
      margin-bottom: 1rem;
    }

    & h4 {
      margin-bottom: 0.5rem;
    }

    & p {
      margin-bottom: 1.5rem;
    }
  }
}

@media (max-width: 992px) {
  .stat_area {
    display: none;
  }
}
