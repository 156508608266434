.header {
  text-align: left;
  align-items: flex-start;
}

.footer {
  display: flex;
  justify-content: center;

  & a {
    border-radius: 40px;
    font-family: 'Noto Sans KR', sans-serif;
  }
}

.card {

  padding: 3rem 3rem 2rem;
  background: #fff;
  box-shadow: 2px 1px 25px 5px rgba(55, 60, 78, 0.05);
  border-radius: 10px;
  transition: all 0.5s ease;
  cursor: default;
  height: 100%;

  &:hover {
    background: rgb(211, 225, 236);
    box-shadow: 2px 1px 25px 5px rgba(55, 60, 78, 0);
  }

  & h4 {
    width: 100%;
  }

  & p {
    margin-bottom: 2rem;
  }

  & i {
    font-size: 3rem;
    margin-top: auto;
  }
}

@media (max-width: 768px) {
  .dummy {
    display: none;
  }

  .header {
    text-align: center;
    align-items: center;

    & h2 {
      margin-bottom: 0;
    }
  }
}