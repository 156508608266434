.userBody {
    margin: 20px 0;
}

.userHeader {
    margin-bottom: 15px;
    font-size: 1.3rem;
}

.userHeader i {
    color: var(--skSecondary);
    margin-right: 10px;
}

.userHeader .userLeft {
    margin-bottom: 0px;
}

.userContent {
    font-size: 1.3rem;
    line-height: 1.5;
    margin-bottom: 20px;
}

.userContent .title {
    color: var(--skSecondary);
    font-weight: bold;
    margin-bottom: 0px;
}

@media only screen and (max-width: 768px) {
    .userHeader .userLeft {
        margin-bottom: 15px;
    }

    .userContent .title {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 450px) {
    .userHeader .userLeft {
        margin-bottom: 15px;
    }

    .userContent .title {
        margin-bottom: 10px;
    }
}

.tf-eligibility {
    background-color: var(--bg-lightBody);
}

.eligibilityBanner .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
            130.35deg,
            #2E90CF 17.35%,
            #302ae9 78.23%
    );
}

.eligibilityBanner .eligibilityWrapper {
    width: max-content;
}

.eligibilityBanner .lBox {
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--white);
    font-family: "Noto Sans KR", sans-serif !important;
    border: rgba(255, 255, 255, 0.4) solid 1px;
    border-radius: 10px 0px 10px 10px;
    padding: 15px 15px;
    margin: 0px 10px 0px 0px;
}

.rText {
    font-family: "Noto Sans KR", sans-serif !important;
    font-weight: 500;
    color: var(--white);
    font-weight: bold;
    line-height: 24px;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid var(--color-6);
}

#education {
    height: 750px !important;
}

@media only screen and (max-width: 992px) {
    #education {
        height: 650px !important;
    }
}

@media only screen and (max-width: 768px) {
    #education {
        height: 580px !important;
    }
}

@media only screen and (max-width: 450px) {
    #education {
        height: 480px !important;
    }
}

.swal-footer {
    text-align: center;
}

.swal-button {
    border-radius: 50px;
}

.modal-md {
    max-width: 600px;
}

.reviewSection {
    background-color: var(--bg-skGray);
}

@media only screen and (max-width: 992px) {
    .modal-md {
        max-width: 600px;
    }
}

@media only screen and (max-width: 768px) {
    .modal-md {
        max-width: 600px;
    }
}

@media only screen and (max-width: 450px) {
    .modal-md {
        max-width: 420px;
    }
}

.seclect-box {
    padding: 30px 35px 30px 30px;
    box-shadow: 4px 4px 60px rgba(99, 69, 237, 0.15);
    border-radius: 7px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: var(--color-4);
}

.seclect-box .sc-button {
    width: 205px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    flex-shrink: 0;
    padding: 0;
}

.close {
    color: var(--color-1);
    text-shadow: unset;
}

.modal-content {
    top: 20vh;
    max-height: 80vh;
    border-radius: 10px;
    padding: 0px 10px 10px 10px;
    background-color: var(white);
}

.login-title {
    color: var(--skSecondary);
    margin-left: 5px;
    font-size: 1.4rem;
}

.login-title span {
    font-size: 2.2rem;
    color: var(--skPrimary);
}

.login-img {
    margin-right: -90px;
    margin-top: -20px;
    width: 220px;
}

.login-form input {
    font-size: 1.4rem;
    color: var(--color-1);
    padding: 5px 12px;
    line-height: 3;
    border-radius: 5px;
    background-color: var(--bg-input);
}

.login-form input::placeholder {
    font-size: 1.4rem;
    color: rgb(167, 165, 165);
}

.errors {
    padding: 5px 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: var(--skPrimary);
}

.cursor-type {
    cursor: pointer;
}

.selectedRow {
    background-color: #535353;
}

.selectedRow {
    color: white !important;
}

.selectedRow .examBtn {
    background-color: #bdbdbd !important;
}

.mtUp-300 {
    margin-top: -280px !important;
}

.astro-move {
    position: relative;
    animation-name: slide;
    animation-duration: 6s;
    animation-delay: 1s;
}

@keyframes slide {
    0% {
        left: 0px;
        top: 0px;
    }
    10% {
        left: 0px;
        top: 0px;
    }
    35% {
        left: 70px;
        top: 0px;
    }
    98% {
        left: 800px;
        top: 0px;
    }
    100% {
        left: 0px;
        top: 0px;
    }
}

.reviewText {
    width: 75%;
    margin-left: 15%;
    margin-bottom: 20px;
    text-align: left;
    font-size: 1.3rem;
    line-height: 1.5;
}

.rt-controls {
    background-color: var(--bg-body) !important;
}

.rt-layout {
    background-color: var(--bg-body) !important;
}

.rt-controls__button svg {
    fill: var(--primary-color3);
}

.eduSliderBox {
    margin: auto;
}

.eduSlider img {
    margin: auto;
}

.eduSliderTxt {
    margin-top: 10px;
    text-align: center;
    color: var(--skSecondary);
}

.eduItem01 {
    z-index: 10;
    /* animation-duration: 1s;
    animation-delay: 1s;
    animation-name: slidein; */
}

.eduItem02 {
    margin-right: -550px;
    z-index: 5;
}

.eduItem03 {
    margin-left: -460px;
}

@media only screen and (max-width: 992px) {
    .eduItem01 {
        width: 70%;
    }

    .eduItem02 {
        width: 50%;
        margin-right: -100px;
    }

    .eduItem03 {
        width: 50%;
        margin-left: -100px;
    }
}

@keyframes slidein {
    from {
        position: relative;
        top: 50;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

.curriBtn {
    margin-top: 25px;
}

.myBtn {
    width: max-content;
    padding: 15px 30px;
}

.myBtn img {
    margin-right: 25px;
}
