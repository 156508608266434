@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

.eduTitle {
    text-align: center;
    margin-bottom: 80px;
}

.eduTitleLeft {
    text-align: left;
}

.eduTitleLeft h3,
.eduTitle h3 {
    font-size: 3.8rem;
    color: var(--skSecondary);
}

.eduTitleLeft p,
.eduTitle p {
    font-size: 2rem;
    font-weight: bold;
}

.eduIconItem {
    width: 170px;
    border: 3px solid var(--skSecondary);
    border-radius: 10px;
    margin: 1%;
    padding: 20px;
    margin-bottom: 50px;
}

.eduIconItem h4 {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: var(--skSecondary);
}

.eduIconItem p {
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
}

.eduIconItem .animateIcon {
    width: 100px;
    padding: 20px;
    margin: auto;
    margin-top: -80px;
    background-color: var(--bg-body);
}

.eduSub .subTitle {
    text-align: center;
    margin-bottom: 50px;
}

.eduSub .subTitle h3 {
    font-size: 2.4rem;
    font-weight: bold;
    color: var(--skPrimary);
}

.eduSub .subTitle p {
    font-size: 1.8rem;
    font-weight: bold;
}

.eduSub .eduSubItem {
    width: 29%;
    margin: 2%;
    padding: 35px;
    border-radius: 30px;
    background-color: var(--bg-body);
    text-align: center;
}

.eduSub .eduSubItem .title {
    margin-top: 20px;
    font-size: 2rem;
    font-weight: bold;
    color: var(--skSecondary);
}

.eduSub .eduSubItem .sub {
    color: var(--skSecondary);
    font-size: 1.6rem;
    font-weight: bold;
}

.eduSub .eduSubItem .content {
    margin-top: 20px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.2;
}

.eduBtn {
    position: relative;
    top: 60px;
}

.eduFa {
    margin-top: 50px;
    background-color: var(--bg-skGray);
}

.eduStep .number {
    color: var(--skSecondary);
    font-size: 2.6rem;
    font-weight: bold;
    border-radius: 50px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    background-color: var(--bg-body);
}

.eduStep .txt {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 20px;
}

.eduJob {
    margin: auto;
    margin-top: 100px;
    margin-bottom: 30px;
    padding: 0 20px 0 20px;
    /* width: fit-content; */
    border-radius: 20px;
    background-color: var(--bg-lightYellow);
}

.eduJob .jobItem {
    text-align: center;
    position: relative;
    top: -50px;
    width: 210px;
    margin: 20px 15px 0px 15px;
    border-radius: 20px;
    background-color: var(--bg-body);
    box-shadow: 0px 5px 10px rgba(152, 102, 38, 0.63);
}

.eduJob .jobItem .jobIcon {
    margin: 30px auto;
    width: 56px;
    height: 56px;
    border-radius: 30px;
    background-image: linear-gradient(132.41deg, #ff8372 18.03%, #ffd27a 81.29%);
}

.eduJob .jobItem p {
    font-size: 1.6rem;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 30px;
}

.innerSection {
    background-color: var(--bg-lightBody);
    padding-bottom: 80px;
}

.courseTitle .eduTitleLeft h3 {
    font-size: 2.4rem;
    font-weight: bold;
}

.courseTitle .eduTitleLeft p {
    font-size: 1.6rem;
    font-weight: bold;
}

.courseStep {
    position: relative;
    z-index: 10;
    margin: 30px 0;
    padding: 50px;
    border-radius: 20px;
    border: 3px solid rgba(246, 75, 7, 0.3);
    background-color: var(--bg-body);
}

.step h4 {
    font-size: 2.2rem;
    font-weight: bold;
    color: var(--skSecondary);
    margin-bottom: 20px;
}

.step p {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.4;
}

.rightArrow {
    opacity: 0.6;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: var(--skSecondary);
}

.rightArrow i {
    color: var(--bg-body);
}

.courseBox {
    margin-top: 30px;
    width: 48%;
    min-height: 350px;
    padding: 30px;
    background-color: var(--bg-body);
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(152, 102, 38, 0.23);
}

.courseBoxSmall {
    margin-top: 30px;
    width: 48%;
    padding: 30px;
    background-color: var(--bg-body);
    border-radius: 20px;
    box-shadow: 0px 5px 10px rgba(152, 102, 38, 0.23);
}

.boxTitle .number {
    width: 43px;
    height: 43px;
    background-color: var(--skSecondary);
    border-radius: 25px;
    margin-right: 20px;
    color: var(--bg-body);
    font-size: 2rem;
    font-weight: bold;
}

.boxTitle .txt {
    color: var(--skSecondary);
    font-size: 2rem;
    font-weight: bold;
}

.boxContent {
    margin: 10px 0px;
}

.boxContent p {
    font-size: 1.4rem;
    font-weight: bold;
}

.boxContent .checker {
    color: var(--bg-body);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    margin: 0 20px 0 10px;
    border-radius: 8px;
    background-color: var(--skSecondary);
}

.coachSection {
    background-color: var(--bg-lightBody);
}

.projectBox {
    margin-top: 50px;
    padding: 30px;
}

.projectBox .projectLeft {
    width: 48%;
}

.projectBox .projectLeft .projectTitle {
    margin-bottom: 30px;
}

.projectBox .projectLeft .projectTitle img {
    position: relative;
    left: -40px;
    top: 20px;
}

.projectBox .projectLeft .projectTitle h3 {
    font-size: 2.8rem;
    font-weight: bold;
    color: var(--skSecondary);
}

.projectBox .projectLeft .projectTitle p {
    font-size: 1.8rem;
    font-weight: bold;
}

.projectBox .projectLeft .content {
    font-size: 1.6rem;
    line-height: 1.4;
}

.projectBottom {
    margin: -50px 20px 0px 20px;
    padding: 30px;
    border-radius: 20px;
    background-color: var(--bg-lightOrange);
    display: flex;
    justify-content: center;
}

.projectBottom .projectList {
    width: 40%;
    margin: 30px 30px 0 30px;
    line-height: 1.8;
    font-size: 1.8rem;
    font-weight: bold;
    color: white;
}

.infraBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.infraBox .leftBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    width: 15%;
    height: 138px;
    border-radius: 20px;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    background-color: var(--bg-pink);
}

.infraBox .centerBox {
    font-family: "Noto Sans KR", sans-serif !important;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1.4;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 15px;
    width: 20%;
    height: 138px;
    border-radius: 20px;
    background-color: var(--bg-body);
}

.infraBox .rightBox {
    display: flex;
    align-items: center;
    width: 55%;
    height: 138px;
    padding: 0px 20px;
    border-radius: 20px;
    border: 3px solid var(--bg-pink);
    background-color: var(--bg-body);
}

.infraBox .rightBox h4 {
    font-family: "Noto Sans KR", sans-serif !important;
    font-size: 2rem;
    color: var(--bg-pink);
    margin-bottom: 10px;
}

.infraBox .rightBox p {
    font-family: "Noto Sans KR", sans-serif !important;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.4;
}

.registerStep {
    padding: 80px 0;
}

.registerStep .stepTitle {
    font-family: "Noto Sans KR", sans-serif !important;
    font-size: 3.4rem;
    font-weight: bold;
    color: var(--skSecondary);
    text-align: center;
}

.registerStep .lottieIcon {
    width: 100px;
    margin: 30px auto;
}

.registerStep .contentTitle {
    width: 70%;
    padding: 20px 100px;
    margin: auto;
    font-family: "Noto Sans KR", sans-serif !important;
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 500;
    text-align: center;
}

.registerStep .subTitle {
    width: fit-content;
    margin: 50px auto;
    text-align: center;
    font-size: 2.8rem;
    font-weight: bold;
    color: var(--skSecondary);
}

.registerStep .subTitle img {
    position: relative;
    top: -20px;
}

.registerStep .reStepBox {
    padding: 30px;
    margin: 60px auto;
    background-color: var(--bg-lightYellow);
    border: 3px solid var(--skSecondary);
    border-radius: 20px;
}

.registerStep .reStepBox .titleBox {
    width: 300px;
    padding: 30px 0;
    margin: auto;
    margin-top: -70px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--skSecondary);
    border-radius: 20px;
    font-size: 2rem;
    font-weight: bold;
    color: white;
}

.registerStep .reStepBox .txt {
    margin: 30px 0;
    text-align: center;
}

.registerStep .reStepBox .inBox {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 30px;
}

.registerStep .reStepBox .txt h4 {
    color: var(--skSecondary);
    margin-bottom: 20px;
}

.registerStep .reStepBox .txt p {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.5;
}

.registerStep .reStepBox .innerImg {
    visibility: visible;
    display: block;
    display: flex !important;
    justify-content: center !important;
    margin: auto !important;
    margin-bottom: 30px;
}

.registerStep .reStepBox .innerImg02 {
    visibility: hidden;
    display: flex;
    display: none;
    justify-content: center;
    margin-bottom: 30px;
}

.sliderRegiBtn {
    position: absolute;
    bottom: 100px;
}

#containercourse {
    width: 70px;
}

.lcPoster {
    background-color: #383838;
    margin-top: -170px;
    padding: 150px 0px 120px 0;
}

.lcPoster img {
    border-radius: 20px;
}

.fontWhite {
    color: var(--bg-body) !important;
}

.curriBox .curriTitle {
    position: relative;
    top: -55px;
    width: fit-content;
    margin: auto;
    padding: 10px 30px;
    background-color: var(--bg-skGray);
    font-family: "Noto Sans KR", sans-serif;
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: -1px;
    color: var(--bg-body);
}

.curriBox img {
    position: absolute;
    top: -25px;
    left: 0px;
}

p,
h1,
h2,
h3,
h4,
h5,
li {
    font-family: "Noto Sans KR", sans-serif !important;
    letter-spacing: -1px;
}

.contentBox {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.contentBox .leftBox {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: var(--bg-lightOrange);
    margin-right: 10px;
    padding: 15px;
    border-radius: 10px;
}

.contentBox .leftBox .jobList {
    text-align: center;
    width: 48%;
    padding: 18px 0;
    margin: 3px;
    font-family: "Noto Sans KR", sans-serif !important;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: -1px;
    border-radius: 10px;
    background-color: var(--bg-body);
}

.contentBox .rightBox {
    width: 45%;
    margin-left: 10px;
}

.contentBox .rightBox .stepList {
    display: flex;
    width: 95%;
    padding: 8px 20px;
    margin-bottom: 5px;
    border-radius: 10px;
    background-color: var(--bg-body);
    font-family: "Noto Sans KR", sans-serif !important;
    font-size: 1.4rem;
    font-weight: 500;
}

.contentBox .rightBox .stepList p:first-child {
    width: 55px !important;
}

.contentBox .rightBox .stepList span {
    color: var(--skSecondary);
    font-size: 1.6rem;
    font-weight: 700;
    margin-right: 10px;
}

.contentBox .rightBox .stepList:last-child {
    margin-bottom: 0px;
}

.innerContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.innerContent .contentBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
}

.innerContent .contentBox .leftBigBox {
    width: 60%;
    padding: 20px 0;
    margin-right: 30px;
    border-top: thin solid var(--bg-lightBody);
    border-bottom: thin solid var(--bg-lightBody);
}

.innerContent .contentBox .leftBigBox .subjectList {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.innerContent .contentBox .leftBigBox .subjectList .checker {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--skSecondary);
    color: var(--bg-body);
    font-size: 1.2rem;
    text-align: center;
    border-radius: 10px;
    margin-right: 10px;
}

.innerContent .contentBox .leftBigBox .subjectList p {
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--bg-body);
}

.innerContent .contentBox .rightSmallBox {
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.innerContent .contentBox .rightSmallBox .rightStep {
    width: 45%;
    margin: 0px 5px;
    padding: 20px;
    border-radius: 15px;
    background-color: var(--bg-body);
}

.innerContent .contentBox .rightSmallBox .rightStep:nth-child(1),
.innerContent .contentBox .rightSmallBox .rightStep:nth-child(2) {
    margin-bottom: 10px;
}

.innerContent .contentBox .rightSmallBox .rightStep h4 {
    color: var(--skSecondary);
    margin-bottom: 10px;
}

.innerContent .contentBox .rightSmallBox .rightStep p {
    padding: 0;
    line-height: 1.3;
    font-family: "Noto Sans KR", sans-serif !important;
    font-size: 1.4rem;
    font-weight: 700;
}

.innerContent .projectListBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
}

.innerContent .projectListBox .listName {
    width: 46%;
    margin: 10px;
    padding: 15px 20px;
    border-radius: 10px;
    background-color: #a0a0a0;
    color: var(--bg-body);
    font-family: "Noto Sans KR", sans-serif !important;
    font-size: 2rem;
    font-weight: 700;
}

.innerContent .projectListBox .listName:last-child {
    visibility: hidden;
}

.curriBox .tabBox {
    margin: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.innerContent .contentInnerTitle,
.curriBox .tabBox .curriSmallTitle {
    margin-bottom: 30px;
    text-align: center;
    font-size: 2rem;
    font-family: "Noto Sans KR", sans-serif !important;
    font-weight: 500;
    color: var(--bg-lightOrange);
}

.curriBox .tabBox .tabBtnList {
    display: flex;
    justify-content: center;
}

.curriBox .tabBox .tabBtnList .tabBtn {
    z-index: 5;
    position: relative;
    top: 10px;
    cursor: pointer;
    background-color: var(--bg-lightOrange);
    width: 190px;
    padding: 20px 0 40px 0;
    margin-right: 5px;
    border-radius: 15px 15px 0 0;
    text-align: center;
    color: var(--bg-body);
    font-size: 1.8rem;
    font-family: "Noto Sans KR", sans-serif !important;
    font-weight: 500;
    transition: 0.1s;
}

.curriBox .tabBox .tabBtnList .tabBtn:last-child {
    margin-right: 0px;
}

.curriBox .tabBox .tabBtnList .tabBtn:hover,
.curriBox .tabBox .tabBtnList .active {
    z-index: 5;
    position: relative;
    padding: 30px 0 40px 0;
    top: 0px;
    transition: 0.1s;
}

.curriBox .tabBox .tabBtnList .active {
    z-index: 10;
    position: relative;
    background-color: var(--bg-body);
    color: var(--skSecondary);
    font-weight: 700;
    transition: 0.1s;
}

.curriBox .tabBox .tabContent {
    z-index: 6;
    position: relative;
    top: -20px;
    margin: auto;
    width: 580px;
    min-height: 250px;
    padding: 50px 20px;
    background-color: var(--bg-body);
    box-shadow: 0px 0px 20px gray;
    border-radius: 20px;
}

.curriBox .tabBox .tabBoxShadow {
    opacity: 0.1;
    z-index: 4;
    position: relative;
    top: -30px;
    margin: auto;
    margin-top: -300px;
    width: 640px;
    min-height: 260px;
    padding: 50px 20px;
    border-radius: 20px;
    background-color: var(--bg-body);
}

.downBtn {
    margin-bottom: -100px;
}

.textLeft {
    text-align: left !important;
}

.instCenter {
    margin: auto;
}

.instTxt {
    width: 60%;
    margin: auto;
    margin-top: 20px;
}

.tabContent .tcBox {
    margin: auto;
    width: 95%;
    padding: 15px 20px;
    background-color: var(--skSecondary);
    color: var(--bg-body);
    border-radius: 15px;
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
    transition: 0.5s;
}

.tabContent .tcContent {
    margin: auto;
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.tabContent .tcContent .tcLeft p {
    font-size: 1.6rem !important;
    font-family: "Noto Sans KR", sans-serif !important;
    font-weight: 700;
}

.tabContent .tcContent .tcPlus {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bg-body);
    background-color: var(--skSecondary);
    opacity: 0.6;
}

.tabContent .tcContent .tcRight {
    font-size: 1.6rem !important;
    font-family: "Noto Sans KR", sans-serif !important;
    font-weight: 700;
}

@media only screen and (max-width: 768px) {
    .edu {
        margin-top: 0px;
    }

    .eduIconItem {
        width: 45%;
        margin-bottom: 60px;
    }

    .eduIconItem .animateIcon {
        width: 90px;
        margin-top: -70px;
    }

    .eduSub {
        margin-top: 50px;
        padding: 20px;
        border-radius: 20px;
    }

    .eduSub .subTitle h3 {
        font-size: 2rem;
        margin-top: 20px;
    }

    .eduSub .eduSubItem {
        width: 100% !important;
        margin-bottom: 20px;
    }

    .eduJob {
        padding: 0px;
    }

    .eduJob .jobItem {
        width: 40% !important;
        margin: 5px;
    }

    .courseStep .step {
        width: 100% !important;
        text-align: center;
        margin-bottom: 20px;
    }

    .rightArrow {
        margin: auto;
        margin-bottom: 20px;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        transform: rotate(90deg);
    }

    .courseBox {
        width: 100%;
    }

    .courseBoxSmall {
        width: 100%;
    }

    .projectBox .projectLeft {
        width: 90% !important;
        margin: auto;
        margin-bottom: 50px;
    }

    .projectBox .projectRight {
        width: 80% !important;
        margin: auto;
    }

    .projectBox .projectRight img {
        width: 100%;
    }

    .projectBottom {
        margin: 0px;
        padding: 20px;
    }

    .projectBottom .projectList {
        width: 100%;
        margin: 30px 0;
    }

    .swiper-wrapper {
        height: 500px !important;
    }

    .infraBox .leftBox {
        width: 40% !important;
        height: 80px;
        margin: 0px;
        border-radius: 10px 10px 0px 10px;
    }

    .infraBox .centerBox {
        width: 60% !important;
        height: 80px;
        margin: 0px;
        border-radius: 10px 10px 10px 0px;
    }

    .infraBox .rightBox {
        width: 100%;
        height: auto;
        padding: 30px 20px;
        border-radius: 10px;
        margin-top: 1px;
        border: none;
    }

    .infraBox .rightBox h4 {
        font-size: 1.6rem;
    }

    .infraBox .rightBox p {
        font-size: 1.3rem;
    }

    .swiper-wrapper-sub {
        height: 520px !important;
    }

    .swiper-wrapper-sub2 {
        height: 640px !important;
    }

    .registerStep {
        padding: 40px 0;
    }

    .registerStep .contentTitle {
        width: 100% !important;
        padding: 20px;
        margin: auto;
        font-size: 1.8rem;
        border-radius: 30px;
    }

    .registerStep .reStepBox .titleBox {
        width: 150px;
    }

    .registerStep .reStepBox .inBox {
        width: 100%;
    }

    .sliderRegiBtn {
        top: 200px;
    }

    .courseTitle {
        justify-content: center !important;
    }

    #containercourse {
        display: none;
    }

    .courseTitle .eduTitleLeft h3 {
        text-align: center;
    }

    .courseTitle .eduTitleLeft p {
        text-align: center;
    }

    .eduTitle {
        margin-bottom: 30px;
    }

    .eduTitle h3 {
        font-size: 2.5rem;
    }

    .curriBox {
        margin: 0px 0px 30px 0 !important;
        padding: 0px !important;
        background-image: none;
    }

    .curriBox .curriTitle {
        padding: 8px;
        top: 0px !important;
        margin-bottom: 20px;
        font-size: 2.1rem !important;
        line-height: 1.3;
        text-align: center;
    }

    .curriBox .curriTitle img {
        width: 30px;
        left: -10px;
        top: -10px;
    }

    .curriBox .contentBox .leftBox {
        width: 100% !important;
        padding: 15px 5px 0px 5px !important;
        border-radius: 10px 10px 0 0;
        margin: 0;
    }

    .curriBox .contentBox .leftBox .jobList {
        width: 48%;
        margin: 2px;
        font-size: 1.3rem;
    }

    .curriBox .contentBox .rightBox {
        width: 100% !important;
        margin: 0;
        padding: 15px 5px 15px 5px !important;
        border-radius: 0px 0px 10px 10px;
        background-color: var(--bg-lightOrange);
    }

    .curriBox .contentBox .rightBox .stepList {
        margin: auto;
        margin-bottom: 3px;
    }

    .contentBox .rightBox .stepList p:last-child {
        width: 80% !important;
        line-height: 1.5;
    }

    .curriBox .tabBox {
        margin: 50px 0px;
    }

    .curriBox .tabBox .tabBtnList .tabBtn {
        width: 33%;
    }

    .curriBox .tabBox .tabContent {
        width: 100%;
    }

    .tabContent .tcContent {
        display: flex;
        flex-direction: column;
    }

    .tabContent .tcContent .tcLeft {
        width: 100% !important;
    }

    .tabContent .tcContent .tcLeft p {
        margin-bottom: 20px;
    }

    .tabContent .tcContent .tcPlus {
        margin-bottom: 20px;
    }

    .innerContent .contentBox .leftBigBox {
        width: 100% !important;
        padding: 20px 0;
        margin-bottom: 30px;
    }

    .innerContent .contentBox .leftBigBox .subjectList .checker {
        padding: 10px;
    }

    .innerContent .contentBox .rightSmallBox {
        width: 100% !important;
    }

    .innerContent .contentBox .rightSmallBox .rightStep {
        margin: 2px;
        margin-bottom: 2px !important;
    }

    .innerContent .projectListBox .listName {
        width: 90% !important;
        margin: 1px 0 0 0 !important;
    }

    .tabBoxShadow {
        display: none !important;
    }

    .instTxt {
        width: 90%;
        font-size: 1.6rem !important;
        line-height: 1.4;
    }

    .stepTitle {
        font-size: 2.6rem !important;
    }

    .subTitle {
        font-size: 2.3rem !important;
    }

    .subTitle img {
        width: 30px;
    }

    .sliderBtn {
        display: block;
        visibility: visible !important;
    }

    .lcPoster {
        margin-top: -100px !important;
    }

    .registerStep .reStepBox .innerImg {
        visibility: hidden;
        display: none !important;
    }

    .registerStep .reStepBox .innerImg02 {
        visibility: visible;
        display: flex !important;
        justify-content: center !important;
        display: block;
        margin: auto !important;
    }

    .registerStep .reStepBox .innerImg02 img {
        margin: auto !important;
    }
}

@media only screen and (max-width: 992px) {
    .edu {
        margin-top: 30px;
    }

    .eduSub {
        margin-top: 50px;
        padding: 20px;
        border-radius: 20px;
    }

    .eduSub .subTitle h3 {
        margin-top: 30px;
    }

    .eduSub .eduSubItem {
        width: 30%;
        margin: 5px;
    }

    .eduBtn {
        position: relative;
        top: 50px;
    }

    .eduJob .jobItem {
        width: 40%;
    }

    .rightArrow {
        width: 30px;
        height: 30px;
        border-radius: 25px;
    }

    .projectBox .projectLeft {
        width: 40%;
        margin: auto;
    }

    .projectBox .projectRight {
        width: 40%;
        margin: auto;
        margin-top: 50px;
    }

    .projectBox .projectRight img {
        width: 100%;
    }

    .infraBox .rightBox h4 {
        font-size: 1.6rem;
    }

    .infraBox .rightBox p {
        font-size: 1.3rem;
    }

    .swiper-wrapper-sub {
        height: 480px;
    }

    .swiper-wrapper-sub2 {
        height: 550px;
    }

    .registerStep .contentTitle {
        width: 80%;
    }

    .registerStep .reStepBox .inBox {
        width: 90%;
    }

    .curriBox .contentBox .leftBox {
        width: 80%;
        padding: 10px 5px;
        border-radius: 10px 10px 0 0;
        margin: 0;
    }

    .curriBox .contentBox .leftBox .jobList {
        width: 48%;
        margin: 2px;
        font-size: 1.3rem;
    }

    .curriBox .contentBox .rightBox {
        width: 80%;
        margin: 0;
        padding: 10px 5px;
        border-radius: 0px 0px 10px 10px;
        background-color: var(--bg-lightOrange);
    }

    .curriBox .contentBox .rightBox .stepList {
        margin: auto;
        margin-bottom: 3px;
    }

    .curriBox .curriTitle {
        padding: 8px 20px;
        top: -50px;
        font-size: 2.5rem;
        text-align: center;
    }

    .curriBox .curriTitle img {
        width: 30px;
        left: -10px;
        top: -10px;
    }

    .innerContent .contentBox .leftBigBox {
        width: 80%;
        padding: 20px 0;
        margin-bottom: 30px;
    }

    .innerContent .contentBox .rightSmallBox {
        width: 80%;
    }

    .innerContent .projectListBox .listName {
        width: 90% !important;
        margin: 2px !important;
    }

    .innerContent .contentInnerTitle {
        margin-top: 30px;
    }

    .lcPoster {
        margin-top: -60px;
    }

    .sliderBtn {
        display: block;
        visibility: visible !important;
    }
}

.lcPoster {
    background-color: #383838;
    margin-top: -150px;
    padding: 150px 0px 120px 0;
}

.lcPoster img {
    border-radius: 20px;
}

@media only screen and (max-width: 1140px) {
    .curriBox {
        margin: 100px 10px;
        padding: 30px 0px;
    }

    .innerContent .projectListBox .listName {
        width: 40%;
        margin: 5px;
        font-size: 1.6rem;
    }
}

.sliderBtn {
    position: relative;
    z-index: 50;
    top: -30px;
    visibility: hidden;
}
