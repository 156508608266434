@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

.features {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: thin solid rgb(230, 225, 212);
  border-radius: 10px !important;
  background-color: rgb(253, 249, 237);
  padding: 30px 0;

  & img {
    width: 10rem;
    margin-bottom: 4rem;
  }
}

.price {
  border-radius: 10px !important;
  padding: 20px !important;
  background-color: rgb(253, 249, 237);
}

.footer {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 3rem !important;

  & span {
    font-size: 1.8rem;
    font-weight: 400;

    &:first-of-type {
      margin-right: 1rem;
    }

    & b {
      font-weight: 600;
      margin-right: 5px;
    }
  }
}

.layout-header {
  font-family: 'Noto Sans KR', sans-serif;

  & p {
    color: white;
  }
}

@media (max-width: 1200px) {
  .features {
    & h4 {
      margin-bottom: 1.7rem;
    }

    & img {
      margin-bottom: 2.5rem;
    }
  }
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    margin-top: 1rem !important;

    & span {
      line-height: 1.3;
    }

    & span:first-of-type {
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
}

@media (max-width: 576px) {
  .features {
    text-align: center;

    & h4 {
      margin-bottom: 1.2rem;
    }

    & img {
      width: 7rem;
    }
  }
}

// Internet Explorer 11
@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {
  .features {

    & h4,
    & p {
      width: 100%;
    }
  }
}