table .MuiTableCell-sizeSmall {
    padding: 6px 16px 6px 16px;
}

.MuiContainer-root {
    margin-bottom: 40px;
}

.recharts-legend-wrapper {
    font-size: 15px;
}

.activeBtn {
    width: 80px;
    height: 40px;
    margin-right: 3px;
    background-color: transparent;
    border: 2px solid #2c95d2;
    border-radius: 5px;
    color: #2c95d2;
    cursor: pointer;
    outline: none;
}

.toAnalysisBtn {
    width: 130px;
    height: 40px;
    margin-right: 8px;
    background-color: transparent;
    border: 2px solid #2c95d2;
    border-radius: 5px;
    color: #2c95d2;
    cursor: pointer;
    outline: none;
}

.toSortBtn {
    width: 130px;
    height: 40px;
    margin-right: 8px;
    background-color: transparent;
    border: 2px solid #d25e2c;
    border-radius: 5px;
    color: #d25e2c;
    cursor: pointer;
    outline: none;
}

.toMyPageBtn {
    width: 160px;
    height: 40px;
    background-color: transparent;
    border: 2px solid #2c95d2;
    border-radius: 5px;
    color: #2c95d2;
    cursor: pointer;
    outline: none;
}

.activeBtn:hover,
.toMyPageBtn:hover,
.toAnalysisBtn:hover {
    background-color: #2c95d2;
    color: #fff;
}

.activeBtn:hover,
.toMyPageBtn:hover,
.toSortBtn:hover,
.toSortBtn.activeBtn {
    background-color: #d25e2c;
    color: #fff;
}

.activeBtn:focus,
.toMyPageBtn:focus,
.toAnalysisBtn:focus {
    outline: none;
}

.activeBtn:focus,
.toMyPageBtn:focus,
.toSortBtn:focus {
    outline: none;
}

.toMyPageBtn > span > .secotext {
    text-align: center;
    align-items: center;
    vertical-align: middle;
}

.excelBtn {
    margin-bottom: 4rem;
    width: 180px;
    height: 40px;
    outline: none;
    border: 0;
    border-radius: 5px;
    background-color: #21a325;
    font-size: 1.3rem;
    color: #fff;
    cursor: pointer;
}

.excelBtn:focus {
    outline: none;
}

.excelBtn:hover {
    background-color: #1b8b1f;
}
