.wrapper {
  z-index: 999;
  position: fixed;
  display: none;
  width: 100vw;
  height: 100vh;

  & > i {
    position: absolute;
    top: 2.5rem;
    right: 4rem;
    color: #fff;
    font-size: 2rem;
    z-index: 1000;
    cursor: pointer;
  }

  &.show {
    display: block;
  }
}

.modal {
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;

  & video {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}


@media (max-width: 576px) {
  .modal {
    width: 90%;
  }
}