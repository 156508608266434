@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

.wrapper {
  margin-bottom: 6rem;
}

.card {
  font-family: 'Noto Sans KR', sans-serif;
  position: relative;
  color: rgb(121, 121, 121);
  box-shadow: 0 0.2rem 0.8rem rgba(40, 40, 50, 0.1);
  background: #fff;
  padding: 3rem 5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  letter-spacing: 0.02rem;
  z-index: 1;
  border: thin solid #dddddd;
  border-bottom: 5px solid;
  border-radius: 5px;
  max-width: 350px;
  height: 100%;

  &.main {
    z-index: 2;
    box-shadow: none;
    padding: 3rem;
    color: rgb(121, 121, 121);

    & p {
      margin-bottom: 1.5rem;
      font-family: 'Noto Sans KR', sans-serif;
    }

    & a {
      display: flex;
      align-items: center;
      font-family: 'Noto Sans KR', sans-serif;

      &:before {
        font-size: 2rem;
        margin-right: 0.5rem;
      }
    }

    &:before {
      content: "";
      font-family: 'Noto Sans KR', sans-serif;
      position: absolute;
      width: calc(100% + 12rem);
      height: calc(100% + 12rem);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      border: thin solid #4c95d2;
      box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2) !important;
      background: rgb(250, 254, 255);
      z-index: -1;
    }
  }

  & figure {
    position: relative;
    width: 7rem;
    margin-bottom: 2rem;
    transform: translateX(1rem);
  }

  & p {
    width: 100%;
    margin-top: 1rem;
    font-family: 'Noto Sans KR', sans-serif;
  }

  & h3 {
    font-weight: 400;
    letter-spacing: normal;
    font-family: 'Noto Sans KR', sans-serif;
  }

  & a {
    display: block;
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: 2rem;
    font-family: 'Noto Sans KR', sans-serif;
  }

  & i {
    font-size: 4rem;
    margin-bottom: 3rem;
  }
}

.header {
  margin-bottom: 7rem;
}

@media (max-width: 992px) {
  .card {
    padding: 3rem;
  }

  .card.main {
    &:before {
      display: none;
    }

    & p {
      margin-bottom: 0;
    }

    color: #000;
    box-shadow: 0 0.2rem 0.8rem rgba(40, 40, 50, 0.1);
  }

  .wrapper {
    margin-bottom: 1.5rem;
  }

  .header {
    margin-bottom: 0;
  }

  .btn {
    display: none;
  }
}