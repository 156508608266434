h2 {
    margin: 0;;
    margin-bottom: 12px;
    font-weight: 700;
}

th.MuiTableCell-root {
    padding: 12px;
}

td.MuiTableCell-root {
    padding: 10px;
}

.capaTestResult1 {
    width: 50%;
    display: inline-block;
    vertical-align: text-top;
}

.capaTestResult2 {
    margin: 0;
    width: 50%;
    display: inline-block;
    vertical-align: text-top;
}

.recharts-responsive-container {
    display: inline-block;
}

.btnGroup {
    margin-top: 30px;
    margin-bottom: 50px;
}

.goBackButton {
    margin-right: 15px;
    width: 110px;
    height: 40px;
    outline: none;
    border: 0;
    border-radius: 5px;
    font-size: 1.4rem;
    background-color: #A9A9A9;
    color: #fff;
    cursor: pointer;
}

.goBackButton:focus {
    outline: none;
}

.goBackButton:hover {
    background-color: #868686;
}

.printButton {
    margin-top: 0;
    width: 110px;
    height: 40px;
    outline: none;
    border: 0;
    border-radius: 5px;
    font-size: 1.4rem;
    background-color: #59B3E7;
    color: #fff;
    cursor: pointer;
}

.printButton:focus {
    outline: none;
}

.printButton:hover {
    background-color: #2C95D2;
}