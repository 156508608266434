.rt {
    font-size: 1.6rem;
    color: rgba(0, 0, 0, 0.5);
}

.rt-element {
    font-size: 1.5rem;
}

.rt-controls__button {
    margin-right: 10px;
    border-radius: 10px !important;
    border-color: #ef531050 !important;
    transition: 0.5s;
    position: relative;
    top: 10px;
}

.rt-controls__button:hover {
    background-color: #ef531086 !important;
    transition: 0.5s;
    position: relative;
    top: 5px;
}

.rt-controls__button svg {
    fill: #ef531086 !important;
    transition: 0.5s;
}

.rt-controls__button:hover svg {
    fill: white !important;
    transition: 0.5s;
}

.footerLogoA {
    text-align: left;
    font-size: 1.2rem;
    font-weight: bolder;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: -20px;
    margin-left: 50px;
    padding-left: 60px;
}

.footerLogoB {
    text-align: left;
    font-size: 2.5rem;
    font-weight: bolder;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: -18px;
    margin-left: 50px;
    padding-left: 60px;
}

.footerRight {
    margin-right: 50px;
    padding-right: 60px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.7rem;
}


.astro-move {
    position: relative;
    animation-name: slide;
    animation-duration: 6s;
    animation-delay: 1s;
}

.font10 {
    font-size: 1.5rem;
}

@keyframes slide {
    0% {
        left: 0px;
        top: 0px;
    }
    10% {
        left: 0px;
        top: 0px;
    }
    35% {
        left: 70px;
        top: 0px;
    }
    98% {
        left: 800px;
        top: 0px;
    }
    100% {
        left: 0px;
        top: 0px;
    }
}

