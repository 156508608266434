.card {
  text-align: left;
  //display: flex;
  //@error: column;
  align-items: center;
  padding: 4rem 3rem;
  margin-bottom: 2rem;
  border: thin solid #e7e7e7;
  box-shadow: 0 0 0 rgba(36, 36, 36, 0.15);
  background-color: white;
  border-radius: 10px;
  transition: all .3s ease;

  &:hover {

    background: rgb(252, 247, 234);
    border: thin solid #e7e7e7;
    box-shadow: 10px 10px 40px #e7e7e7;
    //transform: translateY(-1rem);
  }

  & figure {
    width: 4rem;
    margin-bottom: 1rem;

    & img {
      width: 4rem;
    }
  }

  & h2 {
    margin-bottom: 3rem;
    font-size: 3rem;
    font-weight: 300;
    transition: all .3s ease;
  }

  & span {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 500;
    //padding-bottom: 2rem;
  }

  & p {
    width: 100%;
    margin-bottom: 3rem;
  }

  & a {
    width: 200px;
  }
}

.footer {
  color: #fff;
  padding: 6rem 3rem;
  transform: translateY(7rem);
  margin-top: -7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & h2 {
    font-weight: 200;
    max-width: 50rem;
    line-height: 1.5;

    & b {
      font-weight: 500;
    }
  }
}

.header {
  margin-top: 0rem;
}

@media(max-width: 768px) {

  .wrapper:not(:last-of-type) .card {
    margin-bottom: 0;
  }

}

@media (max-width: 576px) {
  .wrapper:last-of-type .card {
    margin-bottom: 1.5rem;
  }

  .footer {
    padding: 3rem;
  }
}