/* 타이머 */
.capaTestBox {
    position: relative;
}

.capaTestTimer {
    width: 100px;
    height: 70px;
    position: absolute;
    top: 28px;
    right: 15px;
}

.capaTestTimer p {
    text-align: center;
    line-height: 1.8;
    color: #FA7F42;
    font-size: 1.6rem;
    font-weight: 500;
}

.limitTime {
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
}