@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

.wTextArea {
    width: 97% !important;
}

.wReply {
    width: 75% !important;
}

.viSection {
    position: relative;
    width: 100%;
}

.vidLeft {
    padding: 0px;
    position: relative;
    background-color: #ffffff;
    margin-right: 3%;
    width: 20%;
    display: inline-block;
    float: left;
    padding-bottom: 5%;
}

.vidRight {
    position: relative;
    width: 77%;
    display: inline-block;
    float: right;
}

.videoRight {
    position: relative;
    width: 77%;
    display: inline-block;
}

.vidLeft h2 {
    font-weight: 900;
    margin-bottom: 10px;
}

.chapterList ul {
    margin-top: 20px;
}

.chapterList ul > a {
    text-decoration: none !important;
}

.chapterList ul > a:hover {
    text-decoration: none !important;
}

.chapterList ul > a > li {
    cursor: pointer;
    padding: 15px 10px;
    border-bottom: #e0e0e0 thin dotted;
    text-decoration: none !important;
}

.chapterList ul > a > li:hover {
    background-color: #3498db;
    font-weight: bolder;
    color: #fff !important;
    text-decoration: none !important;
}

.chapterList ul > a > li:hover > span {
    visibility: hidden;
}

.videoTitle {
    background-color: #f0f5f9;
    border-radius: 5px;
    border: thin dotted aqua;
    margin-bottom: 20px;
}

.videoTitle p {
    font-size: 22px;
    font-weight: 900;
    color: #383b61;
    padding: 15px;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    border-radius: 10px;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.videoSection {
    position: relative;
    padding: 15px;
    top: 50px;
}

.videoSection p {
    color: #383b61;
    padding: 12px;
    font-size: 16px;
    font-weight: 900;
    color: #383b61;
}

.videoSection-text {
    margin-bottom: 50px;
    position: relative;
    padding: 15px;
    background-color: #f0f5f9;
    border-radius: 5px;
    top: 50px;
}

.videoSection-text p {
    color: #383b61;
    padding: 12px;
    font-size: 12px;
    line-height: 1.4;
    color: #383b61;
}

.viSectionDetail {
    position: relative;
    top: 30px;
}

.viSectionDetail p {
    color: #383b61;
    padding: 12px;
    font-size: 16px;
    font-weight: 900;
    color: #383b61;
}

.vidLeftDetail {
    background-color: #ffffff;
    margin-right: 20px;
}

.vidLeftDetail h2 {
    font-weight: 900;
    margin-bottom: 10px;
}

.vidLeftDetail h4 {
    padding: 10px 15px 5px 15px;
    border-radius: 20px;
    width: fit-content;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #3498db;
    margin-bottom: 5px;
}

.vidLeftDetail p {
    line-height: 1.6;
    font-size: 11px !important;
    color: #383b61;
}

.videoDetailTitle p {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 500;
    color: #4e93e0;
    line-height: 1.4;
}

.videoDetailTitle h2 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 1.3em;
    font-weight: 700;
    color: #4e93e0;
    line-height: 1.4;
}

.videoDetailTitle h3 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    color: #383b61;
    line-height: 1.4;
    margin-bottom: 5px;
}

.rtl {
    float: right;
    text-align: right;
}

.videoDetailBottom h2 {
    margin-top: 20px;
    width: 150px;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #f1ba28;
    text-align: center;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 1em;
    font-weight: 700;
    color: white;
    line-height: 1.4;
}

.videoDetailBottom h4 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 0.9em;
    font-weight: 500;
    padding: 10px;
    margin-bottom: 30px;
    color: #383b61;
    line-height: 1.4;
}

.videoDetailBottom p {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    color: #383b61;
    line-height: 1.6;
}

.introTitle h2 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 1.3em;
    font-weight: 700;
    color: #4e93e0;
    line-height: 1.4;
    margin-bottom: 5px;
    margin-top: 20px;
}

.introTitle p {
    width: 90%;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 0.9em;
    font-weight: 300;
    color: #383b61;
    line-height: 1.4;
    margin-bottom: 20px;
}

.introLearningObject h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 150px;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #f1ba28;
    text-align: center;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 1em;
    font-weight: 700;
    color: white;
    line-height: 1.4;
}

.introLearningObject p {
    padding-left: 0;
}

.introDiff h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    width: fit-content;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #a8a9a9;
    text-align: center;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 1em;
    font-weight: 700;
    color: white;
    line-height: 1.4;
}

.instTitle h2 {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 1.3em;
    font-weight: 700;
    color: #4e93e0;
    line-height: 1.4;
    margin-bottom: 5px;
    margin-top: 20px;
}

.instDetail01 img {
    background-color: #383b614f;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 0px;
    margin: 50px 15px;
}

.instDetail02 {
    margin-top: 40px;
}

.instDetail03 {
    background-color: #f1ba28;
    border-radius: 10px;
    padding: 2rem;
}

.instDetail03 p {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 1.65rem;
    font-weight: 400;
    color: white;
}

.toggleBtn {
    position: absolute;
    right: 30px;
    top: 20px;
}

@media (max-width: 1500px) {
    .chapterList ul > a > li {
        font-size: 15px;
        padding: 10px 13px;
    }

    .chapterList ul > a > li > span {
        visibility: hidden;
    }

    .chapterList ul > a > li:hover {
        padding: 10px 13px;
    }

    .vidLeft h2 {
        font-size: 26px;
    }

    .secotext {
        font-size: 15px;
    }

    .secosub1 {
        font-size: 19px;
    }

    .secosub2 {
        font-size: 17px;
    }

    .vidLeft {
        margin-right: 3%;
        width: 22%;
    }

    .vidRight {
        width: 75%;
    }

    .videoRight {
        width: 75%;
    }
}

@media (max-width: 1200px) {
    .chapterList ul > a > li {
        font-size: 13px;
        padding: 9px 7px;
    }

    .chapterList ul > a > li:hover {
        padding: 9px 7px;
    }

    .secotext {
        font-size: 13px;
    }

    .secosub1 {
        font-size: 16.3px;
    }

    .secosub2 {
        font-size: 14.6px;
    }

    .wReply {
        width: 85% !important;
    }

    .modReply {
        top: 40px !important;
        right: 10px !important;
        width: 60px !important;
    }

    .delReply {
        top: 125px !important;
        right: 10px !important;
        width: 60px !important;
    }
}

@media (max-width: 1050px) {
    .chapterList ul > a > li {
        font-size: 8px;
        padding: 8px 5px;
    }

    .chapterList ul > a > li:hover {
        padding: 8px 5px;
    }

    .videoDetailTitle {
        display: none;
    }
}

@media (max-width: 992px) {
    .wReply {
        width: 80%;
    }

    .modReply {
        top: 40px !important;
        right: 10px !important;
        width: 60px !important;
    }

    .delReply {
        top: 125px !important;
        right: 10px !important;
        width: 60px !important;
    }
}

@media (max-width: 820px) {
    .vidLeft {
        margin-right: 3%;
        width: 25%;
    }

    .vidRight {
        width: 72%;
    }

    .videoRight {
        width: 72%;
    }
}

@media (max-width: 767px) {
    .wReply {
        width: 93%;
    }

    .modReply {
        font-size: 13px !important;
        top: 40px !important;
        right: -25px !important;
        width: 50px !important;
    }

    .delReply {
        font-size: 13px !important;
        top: 125px !important;
        right: -25px !important;
        width: 50px !important;
    }
}

@media (max-width: 650px) {
    .vidLeft {
        margin-right: 3%;
        width: 31%;
    }

    .vidRight {
        width: 66%;
    }

    .videoRight {
        width: 66%;
    }
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

.secotitle {
    font-family: "Noto Sans KR";
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.4px;
    line-height: 160%;
}

.secomain {
    font-family: "Noto Sans KR";
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -0.25px;
    line-height: 160%;
}

.secosub1 {
    font-family: "Noto Sans KR";
    font-size: 22.5px;
    font-weight: 600;
    letter-spacing: -0.225px;
    line-height: 160%;
}

.secosub2 {
    font-family: "Noto Sans KR";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 160%;
}

.secotext {
    font-family: "Noto Sans KR";
    font-size: 17.5px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.175px;
}

.secomin {
    font-family: "Noto Sans KR";
    font-size: 15px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.15px;
}

.secoblack {
    color: #212529;
}

.secoblue {
    color: #2e94d2;
}

.secodgray {
    color: #808080;
}

.secogray {
    color: #a5a7a8;
}

.secoyellow {
    color: #f5bb28;
}

.secowhite {
    color: #fff;
}

.blank {
    width: 100%;
    height: 5%;
}

.left {
    position: relative;
    float: left;
    border: 1px solid black;
    height: 95vh;
    width: 30vw;
    overflow-y: "scroll";
    /* background-color: #252526; */
    /*#252526 오픈이지*/
    /*#1b2b34 남색*/
}

.secureTitle {
    position: relative;
    bottom: 20%;
    left: 3%;
    width: 100%;
    height: 5%;
    font-size: 20px;
    /* text-shadow: 2px 2px 2px gray; */
    color: white;
}

.secureLanguage {
    text-align: center;
    width: 100%;
    height: 5%;
    font-size: 20px;
    /* text-shadow: 2px 2px 2px gray; */
    color: #054a91;
    /* background-color: #252526; */
}

.selectDiv {
    position: relative;
    top: 60px;
    height: 40px;
    font-size: 20px;
    /* text-shadow: 2px 2px 2px gray; */
    color: white;
    background-color: #252626;
}

.selectTitle {
    position: relative;
    float: left;
    top: 10px;
    left: 5%;
    font-size: 20px;
    text-transform: uppercase;
    /* text-decoration: underline dotted #E18728;
      text-underline-position: under; */
}

.selectCode1 {
    position: relative;
    float: left;
    height: 30px;
    left: 0%;
    /* border-radius:0.3em;
      border : 1px solid white; */
}

.selectCode2 {
    position: relative;
    float: left;
    height: 20px;
    left: 50%;
    font-size: 20px;
    text-align: center;
    align-items: center;
    /* border-radius:0.3em;
      border : 1px solid white; */
}

.stepDiv {
    border-top: thin solid rgba(255, 255, 255, 0.2);
    position: fixed;
    width: 360px;
    bottom: 180px;
    margin-left: 15px;
    padding-left: 15px;
    font-size: 16px;
    /* text-shadow: 2px 2px 2px gray; */
    color: white;
    /* text-decoration: underline dotted #E18728;
      text-underline-position: under; */
    /* background-color: #252526; */
}

.stepDiv2 {
    position: fixed;
    bottom: 10px;
    width: 380px;
    margin-top: -20px;
    /* text-decoration: underline dotted #E18728;
      text-underline-position: under; */
    /* background-color: #252526; */
}

.pdf {
    position: relative;
    top: 20%;
    width: 95%;
    left: 5%;
    font-size: 20px;
    color: #054a91;
    /* text-decoration: underline dotted #E18728;
      text-underline-position: under; */
    /* background-color: #252526; */
}

.pdf1 {
    position: relative;
    top: 20%;
    width: 95%;
    left: 5%;
    font-size: 20px;
    color: #054a91;
    /* text-decoration: underline dotted #E18728;
      text-underline-position: under; */
}

.video {
    position: relative;
    top: 25%;
    height: 5%;
    width: 95%;
    left: 5%;
    font-size: 20px;
    color: #054a91;
    /* text-decoration: underline dotted #E18728;
      text-underline-position: under; */
    /* background-color: #252526; */
}

.stepNumber {
    position: relative;
    top: 10%;
    /* background-color: #1b2b34; */
}

.horizontal {
    position: relative;
    float: left;
    height: 90vh;
    width: 5px;
    background-color: brown;
    cursor: ew-resize;
    resize: horizontal;
}

.right {
    position: relative;
    float: left;
    border: 1px solid black;
    height: 95vh;
    width: 68.5vw;
}

.titleTop {
    position: relative;
    height: 5%;
    width: 100%;
    border-bottom: 2px solid gray;
    border-right: 1px solid black;
    background-color: #323232;
}

.titleBottom {
    position: relative;

    height: calc(7% + 1px);
    width: 100%;
    border-top: 2px solid gray;
    border-right: 1px solid black;
    /* background-color: #1b2b34; */
}

#exit {
    position: absolute;
    height: 65%;
    top: 15px;
    right: 30px;
    font-size: 12px;
    border-radius: 0.3em;
    border: 1px solid rgba(177, 65, 65, 0.4);
    font-weight: bold;
    padding: 10px !important;
    background-color: rgba(177, 27, 27, 0.4);
    /* background-color: gray; */
}

#exit:hover {
    background-color: rgba(177, 27, 27, 0.9);
}

.step2Title {
    position: absolute;
    height: 80%;
    top: 40%;
    float: left;
    left: 50%;
}

.step_number {
    position: relative;
    height: 80%;
    top: 10%;
    /* float: right; */
    left: 52%;
    font-size: 100%;
    border-radius: 0.3em;
    border: 1px solid gray;

    font-weight: bold;
    /* background-color: gray; */
}

.modalText {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 18px;

    width: 100%;
    height: 100%;
    line-height: 2em;
    color: rgba(5, 5, 5, 0.932);
}

#hint {
    font-family: "Noto Sans KR", sans-serif;
    position: absolute;
    height: 50%;
    top: 20%;
    right: 192px;
    font-size: 11px;
    border-radius: 0.3em;
    border: 1px solid rgba(99, 168, 96, 0.4);
    font-weight: bold;
    padding: 10px !important;
    background-color: rgba(73, 141, 73, 0.4);
}

#hint:hover {
    background-color: rgba(54, 138, 54, 0.9);
}

.hintTitle {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: rgba(13, 97, 11, 0.932);
}

#guide {
    font-family: "Noto Sans KR", sans-serif;
    position: absolute;
    height: 50%;
    top: 20%;
    right: 287px;
    font-size: 11px;
    border-radius: 0.3em;
    border: 1px solid rgba(204, 55, 154, 0.4);
    font-weight: bold;
    padding: 10px !important;
    background-color: rgba(78, 28, 131, 0.4);
}

#guide:hover {
    background-color: rgba(199, 131, 176, 0.4);
}

.guideTitle {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: rgb(107, 14, 76);
}

#submit {
    font-family: "Noto Sans KR", sans-serif;
    position: absolute;
    height: 50%;
    top: 20%;
    right: 30px;
    font-size: 11px;
    border-radius: 0.3em;
    border: 1px solid rgba(65, 106, 177, 0.4);
    font-weight: bold;
    padding: 10px !important;
    background-color: rgba(27, 79, 177, 0.4);
}

#submit:hover {
    background-color: rgba(27, 80, 177, 0.9);
}

#reset {
    font-family: "Noto Sans KR", sans-serif;
    position: absolute;
    height: 50%;
    top: 20%;
    /* float: right; */
    right: 110px;
    font-size: 11px;
    border-radius: 0.3em;
    padding: 10px !important;
    border: 1px solid rgba(221, 128, 112, 0.4);
    font-weight: bold;
    background-color: rgba(243, 64, 32, 0.4);
}

#reset:hover {
    background-color: rgba(243, 64, 32, 0.9);
}

.eidtorSize {
    position: relative;
    top: 64px;
    height: calc(100vh - 60px);
    width: 100%;
    background-color: #202020;
}

.lineDecoration {
    background-color: black;
}

.marginDecoration {
    background-color: black;
}

.myInlineDecoration {
    color: lightgreen !important;
    cursor: pointer;
    /*text-decoration: underline;*/
    font-weight: bold;
    font-style: oblique;
}

.myDiffInlineDecoration {
    color: lightgreen !important;
    /*text-decoration: underline;*/
    font-weight: bold;
    font-style: oblique;
}

.modalSize {
    width: 380px;
    height: 350px;
}

.modalCorrect {
    position: relative;
    top: 5%;
    display: flex;
    justify-content: center; /*가로에서 가운데에 요소를 배치하겠다*/
    /* align-items: center;  세로에서 가운데에 요소를 배치하겠다 style={{backgroundColor:"black"}}*/
    font-size: 24px;
    font-weight: bolder;
    color: gray;
}

.modal {
    background-color: rgba(0, 0, 0, 0.1);
}

.modalNext {
    position: relative;
    top: 5%;
    color: gray;
    display: flex;
    justify-content: center; /*가로에서 가운데에 요소를 배치하겠다*/
}

.myToolbar {
    height: 10%;
}

.monaco-editor {
    padding: 0 0 0 0;
}

.reBtn {
    position: relative;
    top: 10%;
    width: 150px;
    padding: 10px 0;
    border-radius: 25px !important;
}

.closeLeftSide {
    width: 100px;
    text-align: right;
    font-size: 12pt;
    color: white;
    padding-right: 24px;
    cursor: pointer;

    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.closeLeftSide:hover {
    color: aqua;
    font-weight: bolder;
    filter: drop-shadow(2px 2px 0.9rem aqua);
    -webkit-transform: translate(-10px, 0);
    -moz-transform: translate(-10px, 0);
    -ms-transform: translate(-10px, 0);
    -o-transform: translate(-10px, 0);
    transform: translate(-10px, 0);
}

.bBtn {
    margin-left: 10px;
    margin-top: 20px;
    width: 150px;
    border-radius: 50px;
    background-color: #039be5;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.bBtn:hover {
    background-color: aqua;
    width: 120px;
}

.bBtn p {
    padding: 15px 30px 15px 20px;
    text-align: left;
    font-size: 16px;
    font-weight: bolder;
    color: white;
}

.bBtnText {
    float: right;
}

.steperText h3,
p {
    color: rgb(151, 151, 151);
    margin: 0;
}

.steperText h3 {
    font-size: 12px;
    font-weight: 700;
    margin: auto;
    padding: 5px 0;
    width: 80px;
    border-radius: 5px;
    border: thin solid rgba(255, 255, 255, 0.2);
}

.steperText p {
    margin-top: -10px;
    padding-top: -15px;
    font-size: 12px;
}

.selectLname h3 {
    color: white;
}

#demo-simple-select-outlined-label {
    color: #d3d3d3 !important;
    font-size: 20px !important;
    font-weight: bolder !important;
    font-family: "Noto Sans KR", "sans-serif" !important;
}

#demo-simple-select-outlined {
    color: aqua !important;
    font-size: 15px !important;
    background-color: #1e1e1e;
    font-family: "Noto Sans KR", "sans-serif" !important;
}

.diffViewport {
    display: none !important;
}

.diffOverview {
    display: none !important;
}

@media (max-width: 1500px) {
    .secotitle {
        font-size: 34px;
    }

    .secomain {
        font-size: 21.25px;
    }

    .secosub1 {
        font-size: 19px;
    }

    .secosub2 {
        font-size: 17px;
    }

    .secotext {
        font-size: 15px;
    }

    .secomin {
        font-size: 13px;
    }
}

@media (max-width: 1200px) {
    .secotitle {
        font-size: 29px;
    }

    .secomain {
        font-size: 21.25px;
    }

    .secosub1 {
        font-size: 16.3px;
    }

    .secosub2 {
        font-size: 14.6px;
    }

    .secotext {
        font-size: 13px;
    }

    .secomin {
        font-size: 12px;
    }
}
