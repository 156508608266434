.card {
    background-color: var(--bg-body);
    transition: all 0.5s ease-in-out;
    position: relative;
    border: 0px solid transparent;
    border-radius: 20px;
    box-shadow: 0px 12px 33px 0px rgb(62 73 84 / 8%);
    height: calc(100% - 30px);
    margin: 1.5rem;
    /* border: none; */
}

.card > hr {
    margin-right: 0;
    margin-left: 0;
}

.card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
}

.card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px);
}

.card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.75rem - 1px);
    border-bottom-left-radius: calc(0.75rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
    border-top: 0;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 3rem;
}

.card-title {
    font-size: 20px;
    font-weight: 500;
    color: #000;
    text-transform: capitalize;
}

.card-title--large {
    font-size: 1.5rem;
}

.card-title--medium {
    font-size: 1rem;
}

.card-title--small {
    font-size: 0.875rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link + .card-link {
    margin-left: 1.25rem;
}

.card-header {
    border-color: #d7dae3;
    position: relative;
    background: transparent;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 575px) {
    .card-header {
        padding: 1.25rem 1rem 1.25rem;
    }
}

[data-theme-version="dark"] .card-header {
    border-color: #333a54;
}

.card-header .card-title {
    margin-bottom: 0px;
}

.card-header .subtitle {
    padding-top: 5px;
    font-size: 14px;
    line-height: 1.5;
}

.card-header:first-child {
    border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px);
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    border-radius: calc(0.75rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
    flex-shrink: 0;
    width: 100%;
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px);
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: calc(0.75rem - 1px);
    border-bottom-left-radius: calc(0.75rem - 1px);
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        display: flex;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .card-deck .card {
        flex: 1 0 0%;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group > .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap;
    }

    .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }

    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.jsgrid-grid-body::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
    background-color: #f5f5f5;
    width: 8px;
    height: 8px;
}

.jsgrid-grid-body::-webkit-scrollbar-track,
.dataTables_scrollBody::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

.jsgrid-grid-body::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #7e7e7e;
}

.btn {
    padding: 0.938rem 1.5rem;
    border-radius: 0.75rem;
    font-weight: 500;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
    outline: 0 !important;
}

@media only screen and (max-width: 1400px) {
    .btn {
        padding: 0.925rem 1.5rem;
    }
}

.btn-transparent {
    background-color: transparent;
}

.btn i {
    font-size: 1.2rem;
}

.light.tp-btn {
    background-color: transparent;
}

.light.btn-default {
    background: #adb6c7;
}

.light.btn-success {
    background-color: #e7faec;
    border-color: #e7faec;
    color: #2bc155;
}

.light.btn-success g [fill] {
    fill: #2bc155;
}

[data-theme-version="dark"] .light.btn-success {
    background-color: rgba(43, 193, 85, 0.1);
    border-color: transparent;
}

.light.btn-success:hover {
    background-color: #2bc155;
    border-color: #2bc155;
    color: #fff;
}

.light.btn-success:hover g [fill] {
    fill: #fff;
}

.light.btn-info {
    background-color: #dee3fa;
    border-color: #dee3fa;
    color: #2f4cdd;
}

.light.btn-info g [fill] {
    fill: #2f4cdd;
}

[data-theme-version="dark"] .light.btn-info {
    background-color: rgba(47, 76, 221, 0.1);
    border-color: transparent;
}

.light.btn-info:hover {
    background-color: #2f4cdd;
    border-color: #2f4cdd;
    color: #fff;
}

.light.btn-info:hover g [fill] {
    fill: #fff;
}

.light.btn-primary {
    background-color: #fdf4f8;
    border-color: #fdf4f8;
    color: #2E90CF;
}

.light.btn-primary g [fill] {
    fill: #2E90CF;
}

[data-theme-version="dark"] .light.btn-primary {
    background-color: rgba(221, 47, 110, 0.2);
    border-color: transparent;
}

.light.btn-primary:hover {
    background-color: #2E90CF;
    border-color: #2E90CF;
    color: #fff;
}

.light.btn-primary:hover g [fill] {
    fill: #fff;
}

.light.btn-primary {
    background-color: #fdf4f8;
    border-color: #fdf4f8;
    color: #2E90CF;
}

.light.btn-primary g [fill] {
    fill: #2E90CF;
}

[data-theme-version="dark"] .light.btn-primary {
    background-color: rgba(221, 47, 110, 0.2);
    border-color: transparent;
}

.light.btn-primary:hover {
    background-color: #2E90CF;
    border-color: #2E90CF;
    color: #fff;
}

.light.btn-primary:hover g [fill] {
    fill: #fff;
}

.light.btn-secondary {
    background-color: #e8deec;
    border-color: #e8deec;
    color: #392540;
}

.light.btn-secondary g [fill] {
    fill: #392540;
}

[data-theme-version="dark"] .light.btn-secondary {
    background-color: rgba(57, 37, 64, 0.5);
    border-color: transparent;
    color: #fff;
}

.light.btn-secondary:hover {
    background-color: #392540;
    border-color: #392540;
    color: #fff;
}

.light.btn-secondary:hover g [fill] {
    fill: #fff;
}

.light.btn-warning {
    background-color: #fff6e0;
    border-color: #fff6e0;
    color: #ffb800;
}

.light.btn-warning g [fill] {
    fill: #ffb800;
}

[data-theme-version="dark"] .light.btn-warning {
    background-color: rgba(255, 184, 0, 0.1);
    border-color: transparent;
}

.light.btn-warning:hover {
    background-color: #ffb800;
    border-color: #ffb800;
    color: #fff;
}

.light.btn-warning:hover g [fill] {
    fill: #fff;
}

.light.btn-danger {
    background-color: #fde6e6;
    border-color: #fde6e6;
    color: #f35757;
}

.light.btn-danger g [fill] {
    fill: #f35757;
}

[data-theme-version="dark"] .light.btn-danger {
    background-color: rgba(243, 87, 87, 0.15);
    border-color: transparent;
}

.light.btn-danger:hover {
    background-color: #f35757;
    border-color: #f35757;
    color: #fff;
}

.light.btn-danger:hover g [fill] {
    fill: #fff;
}

.light.btn-dark {
    background-color: #dee2e6;
    border-color: #dee2e6;
    color: #3e4954;
}

.light.btn-dark g [fill] {
    fill: #3e4954;
}

[data-theme-version="dark"] .light.btn-dark {
    background-color: rgba(62, 73, 84, 0.35);
    border-color: transparent;
    color: #fff;
}

.light.btn-dark:hover {
    background-color: #3e4954;
    border-color: #3e4954;
    color: #fff;
}

.light.btn-dark:hover g [fill] {
    fill: #fff;
}
