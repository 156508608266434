@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;700;900&display=swap');

.portfolio {
  display: flex;
  margin-top: 50px;
  //border-bottom: 2px solid;
  flex-wrap: wrap;
}

.wrapper {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  padding: 0 20px 0 0;
  background-color: #fff;

}

.image {
  position: relative;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: thin solid #e7e7e7 !important;
  box-shadow: 10px 10px 20px #e7e7e7;

  border-radius: 10px;

  &:hover .button {
    width: 100%;
    height: 20rem;
    opacity: 1;
    transform: translateY(0);
    background: rgba(0, 0, 0, 0.8);
  }

  & .button {
    opacity: 0;
    height: 20rem;
    border-radius: 10px;
    padding: 8%;
    transform: translateY(0);
    position: relative;
    transition: all .3s ease;
  }

  & .button p {
    font-family: 'Noto Sans KR', sans-serif !important;
    color: #fff;
    font-weight: 400;
    margin: 0.5rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1.2;
    text-align: left;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

  }

  & .button h3 {
    font-family: 'Noto Sans KR', sans-serif !important;
    color: #fff !important;
    letter-spacing: 0;
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: 2%;
  }

  & .button button {
    background-color: #f5bb28;
    width: 100%;
    height: 30%;
    margin-top: 2%;
  }

  & .button ready {
    background-color: #A6A6A6;
    width: 100%;
    height: 30%;
  }


  &:before {
    content: "";
    position: relative;
    top: 0;
    left: 0;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.0);
  }
}


.info {
  padding-top: 1.5rem;
  padding-bottom: 5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  & p {
    text-align: left;
    padding-top: 0.2rem;
    font-family: 'Noto Sans KR', sans-serif !important;
    line-height: 130%;
  }

  & span {
    font-family: 'Noto Sans KR', sans-serif !important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.4rem;
  }

  & h3 {
    font-family: 'Noto Sans KR', sans-serif !important;
    margin-top: 0;
    text-transform: capitalize;
    font-size: 2.0rem;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 1.3;

    & a {
      color: #000;
      font-family: 'Noto Sans KR', sans-serif !important;
      text-decoration: none !important;
    }
  }

  & > a {
    display: block;
    text-decoration: none !important;
    padding-top: 2rem;
    margin-top: auto;
    font-family: 'Noto Sans KR', sans-serif !important;
    font-size: 0.9rem;
    font-weight: 600;
    color: #34deeb;
    display: flex;
    align-items: center;
    transition: all .3s ease;

    &:hover {
      color: #000;
    }

    &:hover i {
      background: #000 !important;
    }
  }

  & i {
    font-family: 'Noto Sans KR', sans-serif !important;
    padding: 0.7rem 1rem;
    margin-left: 3rem;
    color: #fff;
    transition: all .3s ease;
  }
}

@media (max-width: 992px) {

  .portfolio {
    padding-bottom: 1.5rem;
  }

  .wrapper:not(:last-of-type) .image {
    border-right: none;
  }

  .image {
    width: 15rem;
    height: 15rem;

    &:hover .button {
      height: 15rem;
    }
  }

  .info {
    display: none;
  }
}

@media (max-width: 768px) {
  .image {
    height: 15rem;
    width: 15rem;

    &:hover .button {
      height: 15rem;
    }
  }
}

@media (max-width: 576px) {
  .portfolio {
    padding-bottom: 0;
  }

  .wrapper {
    flex-direction: column;
    margin: 0;
  }
}

.subTitle {
  font-family: 'Noto Sans KR', sans-serif !important;
  font-weight: 700;
  font-size: 1.3rem;

  & h3 {
    font-family: 'Noto Sans KR', sans-serif !important;
    margin-top: 0.5rem;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.3;
  }
}
