@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

label {
    font-size: 8px;
    font-family: 'Noto Sans KR', sans-serif;
}

.MuiFormLabel-root {
    font-size: 0.9rem !important;
    font-family: 'Noto Sans KR', sans-serif;
}

.signConfirmModal {
    width: 600px;
    height: 80vh;
    overflow-y: auto;
    margin: auto;
    padding: 30px;
    background-color: white;
}