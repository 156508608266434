@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

.detailContainer {
    padding: 0 1.5rem;
}

.detailBox {
    height: 100%;
    padding: 1rem 0;
    border-top: thin dashed #e7e7e7;
    font-family: 'Noto Sans KR', sans-serif;
}

.detailBox:first-child {
    border-top: thin solid #e7e7e7;
    border-radius: 5px 5px 0 0;
    background-color: rgba(0, 0, 0, 0.05);
}

.detailBox .last {
    border-bottom: thin solid #e7e7e7;
    margin-bottom: 20px;
}

.detailLeft {
    border-right: thin solid #e7e7e7;
    font-size: 1.2rem;
    line-height: 1.8;
    color: gray;
}

.detailcontentBox {
    height: auto;
    padding: 1rem 0;
    border-top: thin dashed #e7e7e7;
    font-family: 'Noto Sans KR', sans-serif;
}

.navBox {
    background-color: rgba(0, 0, 0, 0.05);
    height: 4rem;
    padding: 1rem 0;
    border-top: thin solid #e7e7e7;
    font-family: 'Noto Sans KR', sans-serif;
}