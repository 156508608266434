@import "bootstrap.min.css";

.stateBtns {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
}

.stateBtns div {
    text-align: center;
    padding: 10px;
    color: white;
    width: 80px;
    flex-grow: 1;
}

.stateBtns .before {
    background-color: #cc8e60;
}

.stateBtns .inprogress {
    background-color: #f47710;
}

.stateBtns .done {
    background-color: #575757;
}

.stateBtns .disabled {
    opacity: 0.3;
}

.examBtn {
    padding: auto;
    margin: auto;
    width: 100px;
    border-radius: 15px;
    padding: 8px;
    color: white;
    text-align: center !important;
}

.examBtn.small {
    width: 70px;
    font-size: 11px;
    font-weight: bold;
}

.examBtn.result {
    background-color: #575757;
}

.examBtn.end {
    background-color: #e1212d;
}

.examBtn.start {
    background-color: #ec7523;
}

.examBtn.done {
    background-color: #f7b73c;
}

.examBtn.wait {
    background-color: #5d6c7d;
}

.examBtn.inprogress {
    background-color: #ea9c73;
}

.more-btn-icon-box {
    background-color: rgba(0, 0, 0, 0.4);
    color: var(--white);
    padding: 8px 10px;
    border-radius: 50%;
    transition: 0.3s;
}

.more-btn-icon-box:hover {
    background-color: var(--primary);
    color: var(--white);
    transition: 0.3s;
}

.more-btn-icon-box-dark {
    background-color: rgba(0, 0, 0, 0.4);
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: var(--white);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: 0.3s;
}

.more-btn-icon-box-dark-svg {
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.4);
    color: var(--white);
    padding: 6px 7px;
    border-radius: 50%;
    transition: 0.3s;
}

.svg-css {
    fill: white;
}

.more-btn-icon-box-dark:hover {
    background-color: var(--dark);
    color: var(--white);
    transition: 0.3s;
}

.more-btn-icon-box-info {
    background-color: rgba(0, 0, 0, 0.4);
    color: var(--white);
    padding: 8px 8px;
    border-radius: 50%;
    transition: 0.3s;
}

.more-btn-icon-box-info:hover {
    background-color: var(--info);
    color: var(--white);
    transition: 0.3s;
}

.rejectComment {
    width: 100%;
    border: 1px solid #e1212d;
    margin: 10px 0;
    background-color: #fff2e9;
    color: #e1212d;
    padding: 25px 30px;
}

.modal-info-title {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.modal-info-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 90px;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.08);
    color: rgba(0, 0, 0, 0.6);
    margin-left: 5px;
    border-radius: 5px;
}

.modal-info-objective {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
}

.detail-problem-options {
    width: 70%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.detail-problem-option {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.6);
}

.detail-problem-option div:first-child {
    margin-right: 10px !important;
}

.detail-problem-option:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.detail-problem-option.answer {
    justify-content: space-between;
}

.detail-problem-option.answer i {
    font-size: 18px;
    color: #f7b73c;
}

.modal-input {
    width: 100%;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    font-size: 12px !important;
    background-color: rgba(0, 0, 0, 0.08) !important;
    margin-left: 5px !important;
    border-radius: 5px !important;
}

.modal-textarea {
    padding: 10px;
    width: 100%;
    height: 80px;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    font-size: 12px !important;
    background-color: rgba(0, 0, 0, 0.08) !important;
    margin-left: 5px !important;
    border-radius: 5px !important;
    resize: none;
}

.modal-input::placeholder,
.modal-textarea::placeholder {
    color: rgba(0, 0, 0, 0.4) !important;
}

.modal-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.modal-option {
    display: flex;
    align-items: center;
    width: 50%;
    padding: 5px 0;
    padding-right: 10px;
}

.modal-option.multiple {
    width: 30%;
    padding-right: 0;
}

.modal-option input {
    margin-right: 15px;
}

.modal-option.multiple input {
    margin-right: 0;
}

.dzu-dropzone {
    flex-direction: row !important;
    justify-content: space-around;
}

.dzu-previewContainer {
    width: 50% !important;
}

.dzu-inputLabelWithFiles {
    margin: auto 0 !important;
}

.modal-image-wrapper {
    position: relative;
    height: 300px;
    margin-bottom: 30px;
    margin-right: 10px;
    background-color: rgba(0, 0, 0, 0.1);
}

.modal-image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.modal-image-wrapper .delete-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
    height: 30px;
    width: 30px;
    top: -15px;
    right: -15px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
}
