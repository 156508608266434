.scrollNavBox {
    margin: auto;
    position: relative;
    z-index: 50;
    top: -60px;
    width: 844px;
    background-color: var(--bg-body);
    border-radius: 20px;
    box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
}

@media only screen and (max-width: 998px) {
    .scrollNavBox {
        visibility: hidden;
        display: none !important;
    }
}
