@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

label {
    font-size: 1.0px;
    font-family: 'Noto Sans KR', sans-serif;
}

.MuiFormLabel-root {
    font-family: "Noto Sans KR";
    font-size: 17.5px !important;
    font-weight: 400 !important;
    letter-spacing: -0.175px !important;
}

.MuiInputBase-root {
    font-family: "Noto Sans KR";
    font-size: 17.5px !important;
    font-weight: 400 !important;
    letter-spacing: -0.175px !important;
}

.MuiFormHelperText-contained {
    font-family: "Noto Sans KR";
    font-size: 17.5px !important;
    font-weight: 400 !important;
    letter-spacing: -0.175px !important;
}

.signupCheck {
    font-size: 1.2rem;
    color: white;
    text-align: center;
    position: relative;
    right: 0px;
    padding: 8px 5px;
    background-color: dimgray;
    /*border: thin solid gray;*/
    border-radius: 5px;
    font-family: 'Noto Sans KR';
}

.signupCheck a {
    color: white;
}

.smallBtn {
    font-size: 0.6rem !important;
}


.relativeBtn {
    padding: 1.6rem 2.9rem !important;
    margin-top: 15px !important;
}