.sliderCharacter img {
    width: 100px;
    position: fixed;
    bottom: 20px;
    z-index: 20;
}

/* .sliderAniActive {
    animation-name: myAni;
    animation-duration: 1s;
    animation-delay: 1s;
} */


/* 
@keyframes myAni {
    from {
        opacity: 0;
        bottom: -50px;
    }
    to {
        opacity: 1;
        bottom: 20px;
    }
} */

.sliderHead {
    position: relative;
    top: -80px;
}

.sliderSub {
    min-height: 200px;
    position: relative;
    top: 50px;
}

.swiper-slide {
    height: 800px !important;
}

@media only screen and (max-width: 768px) {
    .swiper-slide {
        height: 700px !important;
    }

    .sliderHead {
        top: -50px !important;
    }
}

@media only screen and (max-width: 992px) {
    .sliderHead {
        top: -100px;
    }

    .swiper-slide {
        height: 750px !important;
    }

    .sliderCharacter img {
        bottom: 0px !important;
    }
}

@media only screen and (max-width: 1140px) {
    .sliderHead {
        top: -80px;
    }
}