.capaTestInfoBox1 {
    margin: 0 auto;
    padding: 15px 20px;
    background-color: #efefef;
    border-radius: 10px;
}

.capaTestInfoBox1 h3 {
    margin-bottom: 15px;
    font-weight: 600;
    color: rgb(44, 149, 210);
}

.capaTestInfoBox1 p {
    padding-left: 1rem;
    color: #575757;
    font-size: 1.55rem;
}

.capaTestInfoBox2 {
    margin-bottom: 30px;
}

.capaTestInfoBox2 h2 {
    margin-top: 30px;
    margin-bottom: 15px;
    padding-left: 1rem;
    font-weight: 600;
}

.capaTestInfoBox2 p {
    padding-left: 1.5rem;
    font-size: 1.6rem;
}

.capaTestInfoBox1 p,
.capaTestInfoBox2 p {
    line-height: 1.8;
}

.btnContainer {
    margin: 0 auto;
    width: 250px;
}

.testStartBtn {
    width: 250px;
    height: 50px;
    outline: none;
    border: 0;
    cursor: pointer;
    background-color: rgb(44, 149, 210);
    color: #fff;
    font-size: 1.5rem;
}

.testStartBtn:hover {
    border: 3px solid rgb(44, 149, 210);
    background-color: transparent;
    color: #000;
}

.testStartBtn:focus {
    outline: none;
}

.testDisabled {
    width: 250px;
    height: 50px;
    outline: none;
    border: 0;
    font-size: 1.5rem;
    background-color: #ccc;
    color: #000;
}
