@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

/* Colors */
/*
dark blue: #476A6F
light blue: #258EA6
dark green: #519E8A
medium green: #7EB09B
light green: #A3C4BC
*/

.wrapper-login {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #37515f;
}

.text-wrapper {
    /* left:10vw; */
    position: relative;
    width: 50vw;
    display: flex;
    float: left;
    flex-direction: column;
}

.text-main {
    transform: translateY(-40%);
    text-transform: uppercase;
    font-family: verdana;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 4em;
    font-weight: 600;
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191,
    1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191,
    1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191,
    1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4),
    1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
    1px 30px 60px rgba(16, 16, 16, 0.4);
}

.form-wrapper {
    /* position : absolute; */
    /* right: 10vw; */
    width: 300px;
    display: flex;
    float: right;
    flex-direction: column;
    padding: 20px 40px;
    border-radius: 10px;
    box-shadow: 0px 10px 50px #555;
    background-color: #ffffff;
}

h1 {
    text-align: center;
    width: 100%;
    color: #111;
    font-weight: lighter;
    font-family: "Noto Sans KR", sans-serif;
}

label {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 0.8em;
    margin-bottom: 0.25em;
    color: #222;
    font-weight: lighter;
}

input {
    font-family: "Noto Sans KR", sans-serif;
    padding: 10px 10px;
    border-radius: 5px;
    outline: none;
    /* border: 1px solid #cfcfcf; 프로젝트 옮기면 풀기 */
}

input::placeholder {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 1.2em;
    font-weight: lighter;
    color: #999;
}

input.error {
    font-family: "Noto Sans KR", sans-serif;
    border: 1px solid red;
}

.errorMessage {
    color: red;
    font-size: 0.75em;
    font-family: "Noto Sans KR", sans-serif;
    display: relative;
}

.firstName {
    margin-right: 1%;
}

.lastName {
    margin-left: 1%;
}

.firstName,
.lastName,
.email,
.password {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.firstName,
.lastName {
    width: 49%;
}

.email,
.password {
    width: 100%;
}

.createAccount {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.createAccount button {
    background-color: #2b4570;
    color: #fff;
    border: 2px solid #fff;
    width: 100%;
    margin-top: 1em;
    padding: 8px 0px;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 1em;
    font-weight: lighter;
    letter-spacing: 1px;
    margin-bottom: 0.25em;
}

.to_signup_button {
    background-color: #2b4570;
    color: #fff;
    border: 2px solid #fff;
    width: 100%;
    margin-top: 10px;
    padding: 8px 0px;
    font-size: 1em;
    font-weight: lighter;
    font-family: "Noto Sans KR", sans-serif;
    letter-spacing: 1px;
    margin-bottom: 0.25em;
}

.createAccount button:hover {
    color: #2b4570;
    background-color: #fff;
    border: 2px solid #2b4570;
}

.to_signup_button:hover {
    color: #2b4570;
    background-color: #fff;
    border: 2px solid #2b4570;
}

.createAccount small {
    color: #999;
    font-weight: lighter;
}

.kakao {
    padding: 0;
    width: 300px;
    height: 44px;
    line-height: 44px;
    color: black;
    background-color: #fee500;
    border: 1px solid transparent;
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.kakao:hover {
    background-color: #fff;
    border: 2px solid #fee500;
}

.kakao_signup {
    padding: 0;
    width: 400px;
    height: 40px;
    line-height: 40px;
    color: black;
    background-color: #fee500;
    border: 1px solid transparent;
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.kakao_signup:hover {
    background-color: #fff;
    border: 2px solid #fee500;
}

.kakao_submitter {
    padding: 0;
    width: 400px;
    height: 40px;
    line-height: 40px;
    color: black;
    background-color: #fee500;
    border: 1px solid transparent;
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.kakao_submitter:hover {
    background-color: #fff;
    border: 2px solid #fee500;
}

.google {
    padding: 0;
    width: 300px;
    border: 1px solid transparent;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}

.google:hover {
    box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.2);
}

.google_signup {
    padding: 0;
    width: 400px;
    border: 1px solid transparent;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}

.google_signup:hover {
    box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.2);
}

.google_submitter {
    padding: 0;
    width: 400px;
    border: 1px solid transparent;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}

.google_submitter:hover {
    box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.2);
}

label {
    font-size: 8px;
    font-family: "Noto Sans KR", sans-serif;
}

.MuiFormLabel-root {
    font-size: 0.9rem !important;
    font-family: "Noto Sans KR", sans-serif;
}

.loginModal {
    width: 380px;
    margin: auto;
    padding: 30px;
    background-color: white;
}
