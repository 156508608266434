
.MuiBox-root {
    padding: 30px 0 0 0 !important;

}

.MuiPaper-elevation4 {
    border-radius: 20px !important;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1) !important;
}

.MuiTabs-scroller {
    border-radius: 5px !important;
    padding: 5px 0;
    border: thin solid #e7e7e7 !important;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1) !important;

}

.mypage .MuiButtonBase-root {
    border-right: thin solid rgba(0, 0, 0, 0.08) !important;
}

.mypage .MuiButtonBase-root:last-child {
    border-right: none !important;

}