.form-control {
    font-size: 1.2rem;
}

.input-search {
    outline: none !important;
    height: 42px;
    border-radius: 10px;
    margin-top: 8px;
    margin-bottom: 20px;
    border: 1px solid #eee;
    padding: 0 8px;
    background-color: var(--bg-body);
}

.input-search:focus {
    border-color: rgba(248, 141, 141, 0.637);
    box-shadow: 0 0 10px rgba(248, 141, 141, 0.637);
}

.filtering-table thead th {
    padding: 15px 10px;
    border-top: none;
    text-align: center;
}

.filtering-table thead th:first-child {
    width: 70px;
}

.filtering-table td {
    padding: 15px 10px;
    max-width: 150px;
    font-size: 1.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.filtering-table tbody tr {
    transition: 0.15s;
}

.filtering-table tbody tr:not(.selectedRow):hover {
    transition: 0.2s;
    background-color: rgba(194, 121, 121, 0.1);
}

.table tfoot tr {
    border-bottom-width: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-color: #eeeeee;
}

.filter-pagination {
    display: inline-block;
    background: var(--bg-body);
    border-radius: 10px;
    padding: 5px 5px;
}

.filter-pagination .previous-button,
.filter-pagination .next-button {
    font-size: 16px;
    border: 0 !important;
    width: auto;
    padding: 14px 20px;
    margin: 0 5px;
    border-radius: 10px;
    display: inline-block;
    background-color: #fff;
    color: #7e7e7e;
}

.filter-pagination .nowPage.next-button {
    font-size: 16px;
    border: 0 !important;
    width: auto;
    padding: 14px 20px;
    margin: 0 5px;
    border-radius: 10px;
    display: inline-block;
    background-color: #ec7422;
    color: #fff;
}

.filter-pagination button:hover {
    background: #dd2f6e;
    color: #fff;
}

.filter-pagination button[disabled] {
    opacity: 0.5;
    cursor: default;
}

.filter-pagination button[disabled]:hover {
    background: #2f363e;
    opacity: 0.5;
    color: #fff;
}

.table-index input[type="number"] {
    background: var(--color-3) !important;
    color: #7e7e7e;
    width: 70px;
    border: 0;
    padding: 6px 0 6px 10px;
}

@media only screen and (max-width: 575px) {
    .filter-pagination .previous-button,
    .filter-pagination .next-button {
        padding: 8px 10px;
        font-size: 14px;
    }
}

.table thead th {
    border-bottom: thin solid rgba(247, 142, 142, 0.2);
    text-transform: uppercase;
    font-size: 1.7rem;
    line-height: 2.3rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: var(--skSecondary);
}

.table td {
    border-top: none;
    border-bottom: thin dashed rgba(0, 0, 0, 0.05);
}
