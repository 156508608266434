.capaTestResult h2 {
    font-weight: 700;
}

.capaTestResult h2:not(:first-child) {
    margin-top: 40px;
}

.capaTestScore {
    margin-bottom: 40px;
}

.capaTestScore h3 {
    margin-top: 15px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 1.7rem;
}

.detailButton {
    margin-right: 15px;
    width: 110px;
    height: 40px;
    outline: none;
    border: 0;
    border-radius: 5px;
    font-size: 1.4rem;
    background-color: #A9A9A9;
    color: #fff;
    cursor: pointer;
}

.detailButton:focus {
    outline: none;
}

.detailButton:hover {
    background-color: #868686;
}

.printButton {
    width: 110px;
    height: 40px;
    outline: none;
    border: 0;
    border-radius: 5px;
    font-size: 1.4rem;
    background-color: #59B3E7;
    color: #fff;
    cursor: pointer;
}

.printButton:focus {
    outline: none;
}

.printButton:hover {
    background-color: #2C95D2;
}