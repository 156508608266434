.card_button {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > *:last-child {
    margin-top: 3rem;
  }
}

@media (max-width: 576px) {
  .card_button > *:last-child {
    margin-top: 2rem;
  }
}
