.login-main-page {
    padding: 100px;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}

.login-main-page .login-wrapper {
    background: #2E90CF;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
}

.login-main-page .text-black {
    color: #222 !important;
}

.login-main-page:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: -1;
}

@media only screen and (max-width: 991px) {
    .login-main-page {
        height: 100vh;
    }
}

@media only screen and (max-width: 575px) {
    .login-main-page {
        height: 100vh;
        padding: 0px;
    }
}

.login-wrapper {
    display: flex;
    min-height: 600px;
    height: calc(100vh - 200px);
    box-shadow: 0 0 60px 10px rgba(85, 44, 44, 0.2);
}

.login-wrapper .login-aside-left {
    max-width: 50%;
    flex: 0 0 50%;
    position: relative;
    display: table;
    padding: 50px;
    z-index: 1;
    /* background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom; */
    background-color: rgba(255, 255, 255, 1);
}

.login-wrapper .login-aside-left:after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    content: "";
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
    /* background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%); */
    /* FF3.6-15 */
    /* background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%); */
    /* Chrome10-25,Safari5.1-6 */
    /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%); */
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); */
    /* IE6-9 */
}

.login-wrapper .login-aside-left .login-description {
    display: table-cell;
    vertical-align: bottom;
    padding-bottom: 40px;
}

.login-wrapper .login-aside-right {
    max-width: 50%;
    flex: 0 0 50%;
}

.login-wrapper .social-icons {
    display: flex;
}

.login-wrapper .social-icons li {
    margin-right: 10px;
}

.login-wrapper .social-icons li a {
    height: 40px;
    width: 40px;
    color: var(--primary);
    border-radius: 40px;
    line-height: 38px;
    font-size: 18px;
    display: inline-block;
    text-align: center;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    border: 1px solid var(--primary);
}

.login-wrapper .social-icons li a:hover {
    background: #fff;
    color: #000;
}

.login-wrapper .login-logo {
    position: absolute;
}

.login-wrapper .login-logo img {
    width: 30%;
}

.login-wrapper .authincation-content {
    background-color: transparent;
    box-shadow: none;
}

.login-wrapper .form-control,
.login-wrapper .btn {
    border-radius: 8px;
}

@media only screen and (max-width: 1199px) {
    .login-wrapper .login-aside-left {
        width: 470px;
    }
}

@media only screen and (max-width: 991px) {
    .login-wrapper {
        height: calc(100vh - 100px);
    }
}

@media only screen and (max-width: 767px) {
    .login-wrapper {
        display: block;
        height: auto;
    }

    .login-wrapper .login-aside-left,
    .login-wrapper .login-aside-right {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .login-wrapper .login-logo {
        position: relative;
        margin-bottom: 20px;
        display: block;
    }

    .login-wrapper .login-logo img {
        width: 50%;
    }

    .login-wrapper .social-icons {
        justify-content: center;
    }

    .login-wrapper .social-icons li {
        margin-left: 5px;
        margin-right: 5px;
    }

    .login-wrapper .login-aside-left {
        text-align: center;
        width: 100%;
        display: block;
    }

    .login-wrapper .authincation-content {
        padding: 30px 10px;
    }
}

@media only screen and (max-width: 575px) {
    .login-wrapper .login-aside-left {
        padding: 50px 30px;
    }

    .login-wrapper .login-aside-left .login-description {
        padding-bottom: 0;
    }

    .login-wrapper h2,
    .login-wrapper .h2 {
        font-size: 1.5rem;
    }

    .login-wrapper h4,
    .login-wrapper .h4 {
        font-size: 0.8rem;
    }
}

.authincation-content {
    background: #fff;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    border-radius: 5px;
}

[data-theme-version="dark"] .authincation-content {
    background: #1e2746;
    box-shadow: none;
}

@media only screen and (max-width: 575px) {
    .auth-form {
        padding: 30px;
    }
}

.auth-form .btn {
    height: 50px;
    font-weight: 700;
}

.auth-form .page-back {
    display: inline-block;
    margin-bottom: 15px;
}
