@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif;
  padding: 3rem;

  & span {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    color: #bbb;
    letter-spacing: 1px;
  }

  & h3 {
    margin-bottom: 4rem;
  }
}

.footer {
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center;
  margin-top: 2rem !important;

  & span {
    font-size: 1.8rem;
    font-weight: 400;
    color: #fff;
    margin-right: 10rem;

    & b {
      font-weight: 600;
      margin-right: 5px;
    }
  }

  & a {
    border-radius: 30px;
    font-family: 'Noto Sans KR', sans-serif !important;
  }
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;

    & span {
      margin-right: 0;
      margin-bottom: 3rem;
    }
  }
}

@media (max-width: 576px) {
  .footer {
    margin-top: 1rem !important;

    & span {
      margin-bottom: 2rem;
    }
  }
}
