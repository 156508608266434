.parallax {
  position: relative;
  background: #ccc;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(rgb(40, 40, 50), 0.8);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.intro {
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  padding: 4rem 6rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transform: translateY(-15rem);
  max-width: 700px;
  margin: 0 auto;

  & p {
    letter-spacing: 1px;
    word-spacing: 2px;
    font-weight: 300;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  & h1 {
    font-size: 6rem;
    font-weight: 200;
    line-height: 1.2;
    margin-bottom: 1.5rem;

    & b {
      font-weight: 400;
    }
  }

  & a {
    transform: translateY(50%);
    align-self: flex-end;
  }
}

.form {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

@media (max-width: 768px) {
  .intro {
    padding: 4rem 4rem 0;

    & h1 {
      font-size: 5rem;
    }
  }
}

@media (max-width: 576px) {
  .intro {
    padding: 2rem 1rem 0 1rem;
    align-items: center;
    text-align: center;
    transform: translateY(-10rem);

    & h1 {
      font-size: 3rem;
    }

    & a {
      align-self: center;
      margin-top: -2rem;
    }
  }
}
