.wrapper {
  margin-top: 4rem;
}

.step {
  position: relative;
  height: 100%;
  width: 80%;
  border: 1px solid #212529;
  border-bottom: transparent;
  border-radius: 5px 5px 0 0;
  padding: 1rem 3rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 30px;

  & .number {
    padding: 0 2rem;
    transform: translateY(-260%);
    background: rgb(255, 255, 255);
    line-height: 1;
    margin-bottom: -4rem;
  }

  & p {
    margin-bottom: 1rem;
  }

  & .info {
    padding-top: 5%;
    transition: all .5s ease;
    opacity: 1;
    width: 100%;
  }
}

.footer {
  border-top: 1px solid #212529;
  transform: translateY(-3rem);
}

@media (max-width: 1500px) {
  .step {
    width: 80%;

    & .number {
      transform: translateY(-300%);
    }
  }
}

@media (max-width: 1200px) {
  .step {
    margin: 0 1rem;
    width: 90%;

    & .number {
      font-size: 17.5px;
      transform: translateY(-350%);
    }
  }
}

@media (max-width: 1050px) {
  .step {
    margin: 0 1rem;
    width: 95%;

    & .number {
      font-size: 17.5px;
      transform: translateY(-350%);
    }
  }
}

@media (max-width: 820px) {
  .wrapper {
    margin-bottom: 4rem;
  }

  .step {
    width: 75%;
    border-bottom: 1px solid #212529;

    & .number {
      font-size: 16px;
      transform: translateY(-370%);
    }
  }

  .footer {
    display: none;
  }
}

@media (max-width: 650px) {
  .wrapper {
    margin-bottom: 0rem;
  }
  .step {
    width: 45%;
  }
  .col_3 > * {
    flex-basis: calc(50% - 3rem);
  }
}