@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

.wrapper {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 1s ease;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 12.7vh;

  & button {
    transform: translateY(-10px);
  }
}

.sticky {
  background-color: white;
  border-bottom: thin solid #e7e7e7;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.02);
  position: fixed;
  top: 0;
}

.hidden {
  position: fixed;
  top: -100%;
}

.btn_desktop a {
  border-radius: 5px !important;
  transform: translateY(-10px);
}

.menu {
  display: flex;

  & > li {
    position: relative;

    & > a,
    & span > a {
      display: block;
      color: gray;
      font-family: "Noto Sans KR", sans-serif;
      line-height: 1.4;
      padding: 4rem 2rem;
      transition: all 0.3s ease;
    }

    & > span > a:after {
      content: "\f107";
      font-family: "Line Awesome Free";
      font-weight: 700;
      margin-left: 7px;
    }

    &:hover ul {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      pointer-events: visible;
    }

    & ul {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 25rem;
      background-color: #fff;
      box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.08);
      border: thin solid #e7e7e7 !important;
      border-radius: 10px;

      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      transform: translateY(50px);
      transition: all 0.5s ease;

      & li {
        border-bottom: thin solid rgb(238, 238, 238);
        transition: all 0.5s ease;

        &:hover {
          background: rgb(252, 247, 234);
          border-radius: 0px;
          transition: all 0.5s ease;
        }
      }

      & li:first-child {
        border-bottom: thin solid rgb(238, 238, 238);
        transition: all 0.5s ease;

        &:hover {
          background: rgb(252, 247, 234);
          border-radius: 10px 10px 0 0;
          transition: all 0.5s ease;
        }
      }

      & li:last-child {
        //border-bottom: thin solid rgb(238, 238, 238);
        transition: all 0.5s ease;
        border-bottom: none;

        &:hover {
          background: rgb(252, 247, 234);
          border-radius: 0 0 10px 10px;
          transition: all 0.5s ease;
        }
      }

      & a {
        color: #000;
        letter-spacing: 1px;
        display: block;
        padding: 2rem;
        transition: all 0.3s ease;
      }
    }
  }
}

.btn_mobile {
  display: none;
  padding: 30px 0;
  border-radius: 10px;
}

@media (max-width: 992px) {
  .header {
    transform: translateY(10px);
  }
  .menu {
    display: none;
  }

  .btn_desktop {
    display: none;
  }

  .btn_mobile {
    display: flex;
  }
}

.dwUserBox {
  padding: 20px;
}

.uImg {
  width: 40px;
  display: inline-block;
}

.uImg img {
  width: 100%;
  border-radius: 5px;
  margin-top: -25px;
}

.uText {
  display: inline-block;
  padding: 0 0 0 20px;
  margin: 10px 0 0 0;
}

.uText h4 {
  font-family: "Noto Sans KR", sans-serif;
  margin: 0;
  padding: 0;
}

.uText p {
  margin: 2px 0 0 0;
  padding: 0;
  font-family: "Noto Sans KR", sans-serif;
}

.divider {
  height: 1px;
  overflow: hidden;
  background-color: #e0e0e0;
}

.signInNavPop {
  padding: 40px !important;
}

body {
  -ms-overflow-style: none;
}

body::-webkit-scrollbar {
  display: none;
}
