.slide {
  width: 80%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 700px;

  // & .iLeft {
  //   transform: translateX(-70px);
  // }

  // & .iRight {
  //   transform: translateX(70px);
  // }

  & i {
    font-size: 3rem;
    line-height: 0;
    cursor: pointer;
    color: #3a3a47;
  }

}

.intro {
  max-width: 550px;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 0px;
  // transform: translateY(30px);
  // transform: translateX(-70px);

  & h1 {
    color: #3a3a47;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 2rem;
    text-align: left;
    width: 100%;
  }

  & h3 {
    color: #62626b;
    margin-bottom: -0.5rem;
    padding-left: 5px;
  }

  & p {
    line-height: 1.4 !important;
    margin-bottom: 8rem;
    line-height: 2.2;
    max-width: 450px;
  }

  & a {
    margin: 20px 0 15px 0;
    border-radius: 5px;
    width: 150px;
  }
}

.slideRight {
  align-items: center;
  margin-right: 0px;
  // transform: translateX(90px);

  & img {
    max-width: 550px;
    transform: translateY(-20px);
  }
}

@media (max-width: 1050px) {
  & .iLeft {
    transform: translateX(-70px);
  }

  & .iRight {
    transform: translateX(70px);
  }

  .slideRight {
    & img {
      max-width: 350px;
    }
  }
}

@media (max-width: 992px) {
  .slide {
    height: 400px;
    transform: translateY(50px);
  }

  & .iLeft {
    transform: translateX(-30px);
  }

  & .iRight {
    transform: translateX(30px);
  }

  .intro {
    max-width: 500px;

    & h1 {

      font-size: 2.5rem;
    }

    & h3 {
      font-size: 1.1rem;
    }

    & p {
      font-size: 1.1rem;

    }
  }
}

@media (max-width: 820px) {
  .slide {
    height: 400px;
  }

  .intro {
    max-width: 400px;
    transform: translateX(0px);

    & h1 {
      font-size: 3rem;
      font-weight: 300;
    }

    & p {
      margin-bottom: 3rem;
    }
  }

  .slideRight {
    display: none;
  }
}

@media (max-width: 576px) {
  .slide {
    height: 400px;

    & i {
      display: none;
    }
  }

  .intro {
    max-width: 100%;
    transform: translateX(0px);
  }

  .slideRight {
    display: none;
  }
}