.registerBox {
    padding: 5%;
}

.registerBox .registerCaution {
    margin: 50px 0px;
    border: 1px solid var(--skSecondary);
    background-color: var(--bg-danger);
    border-radius: 10px;
    padding: 30px;
}

.registerBox .registerCaution p {
    color: var(--color-8);
}

.registerBox form .formRow {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px;
}

.registerBox form .formRow h4 {
    font-size: 1.8rem;
    margin-bottom: 5px;
}

.registerBox form .formRow h5 {
    font-size: 1.3rem;
    margin-bottom: 5px;
}

.registerBox form .formRow p {
    color: var(--skSecondary);
}

.registerBox form .formNotice {
    padding: 5px;
}

.registerBox form .formNotice .title {
    color: var(--skSecondary);
    font-size: 1.5rem;
    font-weight: bold;
    margin: 50px 0 10px 0;
}

.registerBox form .formNotice .text {
    color: var(--color-2);
    font-size: 1.2rem;
    line-height: 16px;
}

.registerBox form .submit {
    padding: 15px 30px;
    margin-top: 80px;
}

.registerBox form .formRow .requireTag {
    color: var(--skSecondary);
    font-size: 2rem;
    font-weight: 500;
}

.registerBox form .formRow button,
.registerBox form .formRow .label-btn {
    color: #fff;
    border-radius: 5px;
    background-color: var(--skSecondary);
    transition: all 0.3s ease 0s;
}

.registerBox form .formRow button:hover,
.registerBox form .formRow .label-btn:hover {
    background-color: var(--skPrimary);
    transition: all 0.3s ease 0s;
}

.registerBox form .formRow input::placeholder {
    font-size: 1.3rem;
    color: var(--color-9);
}

.registerBox form .formRow textarea::placeholder {
    font-size: 1.3rem;
    color: var(--color-9);
}

.registerBox form .formRow .inputMsize {
    width: 50%;
}

.registerBox form .formRow .inputLsize {
    width: 100%;
    margin-bottom: 5px;
}

.registerBox form .formRow .form-check label {
    font-size: 1.37rem;
}

.registerBox form .formRow .radioAndInput {
    width: 100% !important;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="radio"],
input[type="radio"]:checked {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    margin-right: 0.2rem;
}

input[type="radio"] {
    background-color: var(--bg-body);
    border: 1px solid var(--skSecondary);
    transition: 0.3s;
}

input[type="radio"]:checked {
    background-color: var(--skSecondary);
    transition: 0.3s;
}

@media only screen and (max-width: 768px) {
    .form-check .form-check-label {
        max-width: 85%;
    }

    .registerBox .titleBox {
        justify-content: center !important;
    }

    .registerBox img {
        display: none;
    }

    .registerBox form .formRow .inputMsize {
        width: 100%;
        margin-bottom: 10px;
    }

    .registerBox form .formRow .inputLsize {
        width: 100%;
    }
}

.form-filename {
    margin-bottom: 15px;
}

.form-filename i {
    margin-right: 5px;
}

.form-error_message {
    color: red;
    margin-top: 20px;
}
