.outer {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: transparent;
  flex-grow: 1;
}

.outer.blur {
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(7px);
  }
}

.outer.dark {
  box-shadow: inset 0 0 0 1000px rgba(rgb(40, 40, 50), 0.8);
}

.outer.light {
  background-color: #f0f5f9;
  /*box-shadow: inset 0 0 0 1000px rgba(#fff, 0.8);*/
}

.outer.padding {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.inner {
  max-width: 1440px;
  width: 100%;
  z-index: 1;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -1.5rem;
}

.wrapper > * {
  flex-basis: calc(100% - 3rem);
  margin: 1.5rem;
}

.col_2 > * {
  flex-basis: calc(50% - 3rem);
}

.col_3 > * {
  flex-basis: calc(33.333333% - 3rem);
}

.col_4 > * {
  flex-basis: calc(25% - 3rem);
}

.col_5 > * {
  flex-basis: calc(20% - 3rem);
}

@media (max-width: 1500px) {
  .inner {
    max-width: 1250px;
    width: 1250px;
  }

  .col_4 > * {
    flex-basis: calc(33.333333% - 3rem);
  }
}

@media (max-width: 1350px) {
  .inner {
    max-width: 1100px;
    width: 1100px;
  }

  .col_4 > * {
    flex-basis: calc(33.333333% - 3rem);
  }
}

@media (max-width: 1200px) {
  .inner {
    max-width: 996px;
    width: 996px;
  }

  .col_4 > * {
    flex-basis: calc(33.333333% - 3rem);
  }
}

@media (max-width: 1050px) {
  .inner {
    max-width: 780px;
    width: 780px;
  }

  .col_4 > * {
    flex-basis: calc(50% - 3rem);
  }
}

@media (max-width: 820px) {
  .inner {
    max-width: 620px;
    width: 620px;
  }

  .col_3 > * {
    flex-basis: calc(50% - 3rem);
  }

  .col_2 > * {
    flex-basis: calc(100% - 3rem);
  }
}

@media (max-width: 650px) {
  .inner {
    max-width: 500px;
    width: 500px;
  }

  .outer.padding {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .col_4 > *,
  .col_3 > * {
    flex-basis: calc(100% - 3rem);
  }
}