.testConfirmModalContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000080;
    z-index: 10000;
}

.testConfirmModal {
    position: absolute;
    width: 350px;
    height: 140px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
}

.testConfirmText {
    margin-top: 30px;
    margin-bottom: 20px;
}

.testConfirmText > p {
    text-align: center;
    font-size: 1.6rem;
    color: #000;
}

.btnGroup {
    margin: 0 auto;
    width: 290px;
    align-items: center;
    text-align: center;
    font-size: 1.3rem;
}

.cancelBtn {
    margin-right: 5px;
    background: #a9a9a9;
    color: #fff;
}

.cancelBtn:hover {
    background: #868686;
    color: #fff;
}

.startBtn {
    background: #59B3E7;
    color: #fff;
}

.startBtn:hover {
    background: rgb(44, 149, 210);
    color: #fff;
}

.cancelBtn, .startBtn {
    width: 140px;
    height: 40px;
    display: inline-block;
    outline: none;
    border: 2px;
    border-radius: 10px;
    cursor: pointer;
}

.cancelBtn:focus, .startBtn:focus {
    outline: none;
}